import { useState } from 'react';
import * as XLSX from 'xlsx';

const NotificationAnalytics = () => {
  // Sample notification list

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(deliveryReportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Delivery Report');
    XLSX.writeFile(wb, 'delivery_report.xlsx');
  };

  const notifications = [
    "Navratri Dussehra Offer 2023 - 1",
    "Diwali Special Offer 2023 - 2",
    "New Year Promotion 2024 - 1"
  ];
  
  // Sample quick overview data
  const quickOverviewData = [
    { value: '8,914', label: 'Total (100%)' },
    { value: '7,865', label: 'Sent (88%)' },
    { value: '6,780', label: 'Sent & Delivered (76%)' },
    { value: '4,832', label: 'Read (54%)' },
    { value: '83', label: 'Skipped (0%)' },
    { value: '965', label: 'Failed (10%)' }
  ];

  // Sample delivery report data
  const deliveryReportData = [
    { name: 'dongdong xu', phone: '+8613576971174', status: 'Read', info: 'Message was read by the contact.', action: null },
    { name: 'allan foster', phone: '+14143231151', status: 'Failed', info: 'Message Undeliverable.', action: 'Retry' },
    { name: 'Vijay Chauhan', phone: '+918350955947', status: 'Delivered', info: 'Message was delivered successfully.', action: null },
    { name: 'Stanly infant', phone: '+917349402325', status: 'Delivered', info: 'Message was delivered successfully.', action: null },
    { name: 'MANGI LAL', phone: '+917023428229', status: 'Delivered', info: 'Message was delivered successfully.', action: null },
    { name: 'Isuru Mallikarachchi', phone: '+94776369425', status: 'Read', info: 'Message was read by the contact.', action: null },
    { name: 'Çağatay Balaban', phone: '+447466002423', status: 'Sent', info: 'Message was sent successfully.', action: null },
    { name: 'Nalin Priyantha', phone: '+94758459816', status: 'Read', info: 'Message was read by the contact.', action: null },
    { name: 'Suneet Sandhu', phone: '+916239414989', status: 'Read', info: 'Message was read by the contact.', action: null },
    { name: 'Yogesh Jadhav', phone: '+919890414656', status: 'Failed', info: 'Message Undeliverable.', action: 'Retry' }
  ];

  const [selectedNotification, setSelectedNotification] = useState(notifications[0]);

  const handleClearReports = () => {
    console.log("Reports & logs cleared!");
  };

  const handleRetry = () => {
    console.log("Retrying...");
  };

  const containerStyle = {
    width: '90%',
    margin: '20px auto',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const headerTitleStyle = {
    fontSize: '24px',
    fontWeight: '500',
  };

  const buttonStyle = {
    backgroundColor: '#ff4d4f',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const selectNotificationStyle = {
    marginBottom: '20px',
  };

  const selectStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #d9d9d9',
    borderRadius: '5px',
  };

  const quickOverviewStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  };

  const quickOverviewCardStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
    flex: 1,
    margin: '0 10px',
  };

  const quickOverviewTitleStyle = {
    fontSize: '24px',
    margin: '0',
  };

  const quickOverviewTextStyle = {
    margin: '5px 0 0',
    color: '#888',
  };

  const deliveryReportStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thTdStyle = {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #f0f0f0',
  };

  const thStyle = {
    backgroundColor: '#fafafa',
  };

  const actionsStyle = {
    textAlign: 'center',
  };

  const actionsButtonStyle = {
    backgroundColor: '#1890ff',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const paginationStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  };

  const infoStyle = {
    color: '#888',
  };

  const pagesStyle = {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
  };

  const pageItemStyle = {
    margin: '0 5px',
  };

  const pageLinkStyle = {
    textDecoration: 'none',
    color: '#1890ff',
    padding: '5px 10px',
    border: '1px solid #d9d9d9',
    borderRadius: '5px',
  };

  const activePageLinkStyle = {
    backgroundColor: '#1890ff',
    color: 'white',
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Read':
        return { color: 'green' };
      case 'Delivered':
        return { color: 'blue' };
      case 'Failed':
        return { color: 'red' };
      case 'Sent':
        return { color: 'orange' };
      default:
        return {};
    }
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1 style={headerTitleStyle}>Notification Analytics</h1>
        <button style={buttonStyle} onClick={handleClearReports}>Clear reports & logs</button>
      </div>

      <div style={selectNotificationStyle}>
        <select
          value={selectedNotification}
          onChange={(e) => setSelectedNotification(e.target.value)}
          style={selectStyle}
        >
          {notifications.map((notification, index) => (
            <option key={index} value={notification}>
              {notification}
            </option>
          ))}
        </select>
      </div>

      <div style={quickOverviewStyle}>
        {quickOverviewData.map((item, index) => (
          <div style={quickOverviewCardStyle} key={index}>
            <h2 style={quickOverviewTitleStyle}>{item.value}</h2>
            <p style={quickOverviewTextStyle}>{item.label}</p>
          </div>
        ))}
      </div>

      <div style={deliveryReportStyle}>
      <div style={{ display:'flex', flexDirection:'row',justifyContent:'space-between', marginTop: '20px' }}>
      <h2 style={quickOverviewTitleStyle}>Delivery Report</h2>
            <button
              style={{
                backgroundColor: '#1890ff',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={exportToExcel}
            >
              Export to Excel
            </button>
            </div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={{ ...thTdStyle, ...thStyle }}>Last Updated</th>
              <th style={thTdStyle}>Name</th>
              <th style={thTdStyle}>Phone Number</th>
              <th style={thTdStyle}>Status</th>
              <th style={thTdStyle}>Info</th>
              <th style={thTdStyle}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {deliveryReportData.map((entry, index) => (
              <tr key={index}>
                <td>21 Oct, 2023 09:52</td>
                <td>{entry.name}</td>
                <td>{entry.phone}</td>
                <td style={getStatusColor(entry.status)}>{entry.status}</td>
                <td>{entry.info}</td>
                <td style={actionsStyle}>
                  {entry.action ? <button style={actionsButtonStyle} onClick={handleRetry}>{entry.action}</button> : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={paginationStyle}>
          <div style={infoStyle}>Showing 1 to 10 of 8,914 entries</div>
          <ul style={pagesStyle}>
            {[1, 2, 3, 4, 5, '...', 892].map((page, index) => (
              <li style={pageItemStyle} key={index}>
                <a href="#" style={pageLinkStyle}>
                  {page}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationAnalytics;
