import React from 'react';

// Sample Data (Replace with backend data later)
const contactData = {
  lists: [
    { id: 1, name: 'Others', contacts: 4 },
    { id: 2, name: 'XYZ', contacts: 1 },
    { id: 3, name: 'Website Leads', contacts: 1 },
  ],
  tags: [
    { id: 1, name: 'new lead1', contacts: 3 },
    { id: 2, name: 'notification sent', contacts: 4 },
    { id: 3, name: 'new', contacts: 6 },
  ],
};

const ContactListsAndTags = () => {
  return (
    <div style={{ display: 'flex', gap: '20px', padding: '20px' }}>
      {/* Contact Lists */}
      <div style={{ flex: 1, backgroundColor: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <h3>Contact Lists</h3>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {contactData.lists.map((list) => (
            <li key={list.id}>{list.name} ({list.contacts} contacts)</li>
          ))}
        </ul>
        <input type="text" placeholder="Add new list" style={{ width: '80%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
        <button style={{ marginLeft: '10px', padding: '8px 16px', backgroundColor: '#009688', color: '#fff', border: 'none', borderRadius: '4px' }}>Add</button>
      </div>

      {/* Contact Tags */}
      <div style={{ flex: 1, backgroundColor: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <h3>Contact Tags</h3>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {contactData.tags.map((tag) => (
            <li key={tag.id}>{tag.name} ({tag.contacts} contacts)</li>
          ))}
        </ul>
        <input type="text" placeholder="Add new tag" style={{ width: '80%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
        <button style={{ marginLeft: '10px', padding: '8px 16px', backgroundColor: '#009688', color: '#fff', border: 'none', borderRadius: '4px' }}>Add</button>
      </div>
    </div>
  );
};

export default ContactListsAndTags;
