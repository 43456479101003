import React, { useState } from 'react';
import './MessageTemplates.css';

const MessageTemplateForm = () => {

    const [templateName, setTemplateName] = useState('');
    const [category, setCategory] = useState('');
    const [language, setLanguage] = useState('');
    const [templateType, setTemplateType] = useState('');
    const [headerType, setHeaderType] = useState('None');
    const [bodyContent, setBodyContent] = useState('');
    const [footerText, setFooterText] = useState('');
    const [status, setStatus] = useState('DRAFT');
    const [buttonType, setButtonType] = useState('custom');
    const [buttonText, setButtonText] = useState('No');
    const [buttonInput, setButtonInput] = useState('');
    const [headerContent, setHeaderContent] = useState(''); // Text or file URL

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setHeaderContent(fileURL);
        }
    };
  
const [headerText, setHeaderText] = useState(''); 
const [headerImage, setHeaderImage] = useState(null); 


    const handleSubmit = () => {
        alert('Template submitted for approval!');
    };

    const renderButtonInput = () => {
        switch (buttonType) {
            case 'visit-website':
                return (
                    <div className="mb-3">
                        <label className="form-label">Website URL</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter website URL"
                            value={buttonInput}
                            onChange={(e) => setButtonInput(e.target.value)}
                        />
                    </div>
                );
            case 'call-phone':
                return (
                    <div className="mb-3">
                        <label className="form-label">Phone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone number"
                            value={buttonInput}
                            onChange={(e) => setButtonInput(e.target.value)}
                        />
                    </div>
                );
            case 'copy-code':
                return (
                    <div className="mb-3">
                        <label className="form-label">Offer Code</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter offer code"
                            value={buttonInput}
                            onChange={(e) => setButtonInput(e.target.value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container mt-4">
            <div className="row">
                {/* Left Section */}
                <div className="col-md-8">
                    <h3 className="mb-4">Add Message Template</h3>
                    {/* Template Name */}
                    <div className="mb-3">
                        <label className="form-label">Template Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                        <small className="form-text text-muted">Spaces and special characters are not allowed.</small>
                    </div>

                    {/* Category & Language */}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Category</label>
                            <select
                                className="form-select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option>Marketing</option>
                                <option>Utility</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Language</label>
                            <select
                                className="form-select"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <option>English</option>
                                {/* <option>Spanish</option> */}
                            </select>
                        </div>
                    </div>

                    {/* Template Type */}
                    <div className="mb-3">
                        <label className="form-label">Template Type</label>
                        <select
                            className="form-select"
                            value={templateType}
                            onChange={(e) => setTemplateType(e.target.value)}
                        >
                            <option>Custom</option>
                       
                        </select>
                    </div>

                    {/* Header */}
                    {/* Header */}
<div className="mb-3">
    <label className="form-label">Header (Optional)</label>
    <select
        className="form-select"
        value={headerType}
        onChange={(e) => {
            setHeaderType(e.target.value);
            setHeaderContent(''); // Reset header content
        }}
    >
        <option value="none">None</option>
                    <option value="text">Text</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
    </select>
</div>

{/* Dynamic Header Input */}
{headerType === 'Text' && (
    <div className="mb-3">
        <label className="form-label">Header Text</label>
        <input
            type="text"
            className="form-control"
            value={headerText}
            onChange={(e) => setHeaderText(e.target.value)}
        />
    </div>
)}

{headerType === 'Image' && (
    <div className="mb-3">
        <label className="form-label">Upload Header Image</label>
        <input
            type="file"
            className="form-control"
            accept="image/*"
            onChange={(e) => setHeaderImage(URL.createObjectURL(e.target.files[0]))}
        />
    </div>
)}

{headerType === 'image' && (
                <div className="mb-3">
                    <label className="form-label">Upload Image</label>
                    <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        onChange={handleFileUpload}
                    />
                </div>
            )}
 {headerType === 'video' && (
                <div className="mb-3">
                    <label className="form-label">Upload Video</label>
                    <input
                        type="file"
                        className="form-control"
                        accept="video/*"
                        onChange={handleFileUpload}
                    />
                </div>
            )}


                    {/* Body */}
                    <div className="mb-3">
                        <label className="form-label">Body</label>
                        <textarea
                            className="form-control"
                            rows="5"
                            value={bodyContent}
                            onChange={(e) => setBodyContent(e.target.value)}
                        ></textarea>
                        <small className="form-text text-muted">
                            Use *text* for bold, _text_ for italics, and `text` for code formatting.
                        </small>
                    </div>

                    {/* Footer */}
                    <div className="mb-3">
                        <label className="form-label">Footer (Optional)</label>
                        <input
                            type="text"
                            className="form-control"
                            value={footerText}
                            onChange={(e) => setFooterText(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Button (Optional)</label>
                        <div className="d-flex align-items-center">
                            <select
                                className="form-select me-2"
                                value={buttonType}
                                onChange={(e) => setButtonType(e.target.value)}
                            >
                                <option value="quick-reply">Quick Reply</option>
                                <option value="custom">Custom</option>
                                <option value="visit-website">Visit Website</option>
                                <option value="call-phone">Call Phone Number</option>
                                <option value="copy-code">Copy Offer Code</option>
                            </select>
                            <input
                                type="text"
                                className="form-control me-2"
                                placeholder="Enter button text"
                                value={buttonText}
                                onChange={(e) => setButtonText(e.target.value)}
                            />
                            <i className="fas fa-trash text-danger" style={{ cursor: 'pointer' }}></i>
                        </div>
                    </div>

                    {renderButtonInput()}

                    {/* Button */}
                    <button className="btn btn-primary" onClick={handleSubmit}>Submit for Approval</button>
                </div>

                {/* Right Section */}
                <div className="col-md-4">
                    {/* Actions */}
                    <div className="card p-3 mb-3">
                        <h5>Actions</h5>
                        <p>Status: <span className="badge bg-secondary">{status}</span></p>
                        <button className="btn btn-success w-100" onClick={handleSubmit}>Submit for Approval</button>
                    </div>

                    {/* Template Preview */}
{/* Template Preview */}
<div className="card p-3">
    <h5>Template Preview</h5>
    <div className="template-preview" >
        {/* Dynamic Header */}
        {headerType === 'Image' && headerImage && (
            <div className="template-body">
                <img src={headerImage} alt="Header Preview" className='template-header-image'/>
            </div>
        )}
        {headerType === 'Text' && headerText && (
            <div className="template-body">
                <h4>{headerText}</h4>
            </div>
        )}

{headerType === 'video' && headerContent && (
      <div className="template-body">
    
                        <video
                            controls
                            style={{ width: '100%', borderRadius: '8px' }}
                        >
                            <source src={headerContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    )}

        {/* Body */}
        <div  style={{ backgroundColor: 'white'}}>
        <div className="template-body">
            <p>{bodyContent || 'Body text here'}</p>
            <div className='d-flex flex-row align-items-center;
    justify-content-between'>
            <small>{footerText}</small>
            <small className="template-footer">10:00 AM</small>
            </div>
        </div>

        {/* Buttons */}
        <div className="template-buttons">
    {buttonType === 'visit-website' && (
        <button>
            <i className="fas fa-external-link-alt"></i> Visit Website
        </button>
    )}
    {buttonType === 'call-phone' && (
        <button>
            <i className="fas fa-phone-alt"></i> Call Phone
        </button>
    )}
    {buttonType === 'copy-code' && (
        <button>
            <i className="fas fa-copy"></i> Copy Offer Code
        </button>
    )}
    {buttonType === 'custom' && (
        <>
            <button>
                <i className="fas fa-check"></i> {buttonText || 'Yes'}
            </button>
            <button>
                <i className="fas fa-times"></i> No
            </button>
        </>
    )}
    {buttonType === 'quick-reply' && (
        <>
            <button>
                <i className="fas fa-check"></i> Yes
            </button>
            <button>
                <i className="fas fa-times"></i> No
            </button>
        </>
    )}
</div>
</div>
    </div>
</div>


                </div>
            </div>
        </div>
    );
};

export default MessageTemplateForm;
