import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Dashboard.css';

const Dashboard = () => {
  const conversationData = {
    businessInitiated: {
      free: 0,
      paid: 22,
      total: 22,
      cost: '₹ 11.38'
    },
    userInitiated: {
      free: 14,
      paid: 0,
      total: 14,
      cost: '₹ 0.00'
    }
  };

  return (
    <div className="container mt-4 dashboard">
      {/* Home Section */}
      <div className="card mb-4 p-3">
        {/* <h4 className="mb-3"><i className="fas fa-home me-2"></i>Home</h4> */}
        <div className="row text-center">
          <div className="col"><strong>Phone Number:</strong><br />+91 83297 69248</div>
          <div className="col"><strong>Display Name:</strong><br />WANotifier Test</div>
          <div className="col"><strong>Messaging Limit:</strong><br />1k/24hr</div>
          <div className="col"><strong>Quality Rating:</strong><br /><span className="text-success">● High</span></div>
          <div className="col"><strong>Status:</strong><br />CONNECTED</div>
        </div>
      </div>

      {/* Conversations & Messaging */}
      <div className="row mb-4">
        {/* Conversations Card */}
        <div className="col-md-8">
          <div className="card p-3">
            <h5><i className="fas fa-comments me-2"></i>Conversations</h5>
            <table className="table table-sm mt-3">
              <thead>
                <tr>
                  <th>Conversations</th>
                  <th>Free</th>
                  <th>Paid</th>
                  <th>Total</th>
                  <th>Approx Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Business-initiated</td>
                  <td>{conversationData.businessInitiated.free}</td>
                  <td>{conversationData.businessInitiated.paid}</td>
                  <td>{conversationData.businessInitiated.total}</td>
                  <td>{conversationData.businessInitiated.cost}</td>
                </tr>
                <tr>
                  <td>User-initiated</td>
                  <td>{conversationData.userInitiated.free}</td>
                  <td>{conversationData.userInitiated.paid}</td>
                  <td>{conversationData.userInitiated.total}</td>
                  <td>{conversationData.userInitiated.cost}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Messaging Card */}
        <div className="col-md-4">
          <div className="card p-3">
            <h5><i className="fas fa-envelope me-2"></i>Messaging</h5>
            <p><strong>Messages Sent:</strong> 310</p>
            <p><strong>Messages Delivered:</strong> 310</p>
          </div>
        </div>
      </div>

      {/* Plan Details & Notifications */}
      <div className="row mb-4">
        {/* WANotifier Plan Card */}
        <div className="col-md-4">
          <div className="card p-3">
            <h5><i className="fas fa-chart-line me-2"></i>WANotifier Plan: Startup</h5>
            <p><strong>Message Templates:</strong> 89 / 250</p>
            <p><strong>Contacts:</strong> 1,207 / 10,000</p>
            <p><strong>Messages:</strong> 517 / 40,000</p>
            <p><strong>API Requests:</strong> 41 / 10,000</p>
          </div>
        </div>
        {/* Notifications Card */}
        <div className="col-md-8">
          <div className="card p-3">
            <h5><i className="fas fa-bell me-2"></i>Notifications</h5>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Sent</th>
                  <th>Delivered</th>
                  <th>Read</th>
                  <th>Failed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Demo Notification</td>
                  <td><span className="badge bg-success">COMPLETED</span></td>
                  <td>4</td><td>2</td><td>2</td><td>0</td><td>0</td>
                </tr>
                <tr>
                  <td>Zapier Integration</td>
                  <td><span className="badge bg-warning">ON-GOING</span></td>
                  <td>3</td><td>2</td><td>2</td><td>0</td><td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Templates & Contacts */}
      <div className="row mb-4">
        {/* Templates Card */}
        <div className="col-md-4">
          <div className="card p-3">
            <h5><i className="fas fa-file-alt me-2"></i>Templates</h5>
            <ul>
              <li>wanotifier_team <span className="badge bg-success">APPROVED</span></li>
              <li>test_template_011 <span className="badge bg-success">APPROVED</span></li>
              <li>test_template_011 <span className="badge bg-success">APPROVED</span></li>
              <li>test_template_011 <span className="badge bg-success">APPROVED</span></li>
              <li>test_template_011 <span className="badge bg-success">APPROVED</span></li>
              <li>test_template_011 <span className="badge bg-success">APPROVED</span></li>
            </ul>
          </div>
        </div>
        {/* Contacts Card */}
        <div className="col-md-4">
  <div className="card p-3">
    <h5><i className="fas fa-address-book me-2"></i>Contacts</h5>
    <div className="contact-list">
      {[
        { name: 'Ronny Demo', number: '+919717886327', status: 'SUBSCRIBED' },
        { name: 'Krish Demo', number: '+91315508280', status: 'SUBSCRIBED' },
        { name: 'Chaitanya Joshi', number: '+918149715614', status: 'SUBSCRIBED' },
        { name: 'S1 S2', number: '+8756437890', status: 'UNSUBSCRIBED' },
        { name: 'Niki Sharma', number: '+918168730641', status: 'SUBSCRIBED' }
      ].map((contact, index) => (
        <div key={index} className="row mb-2 align-items-center text-center border-bottom pb-2">
          <div className="col-md-4 fw-bold">{contact.name}</div>
          <div className="col-md-4 text-muted">{contact.number}</div>
          <div className="col-md-4">
            <span className={`badge ${contact.status === 'SUBSCRIBED' ? 'bg-primary' : 'bg-secondary'}`}>
              {contact.status}
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>


        <div className="card p-3 col-md-4">
        <h5><i className="fas fa-link me-2"></i>Quick Links</h5>
        <ul>
          <li><a href="#"><i className="fas fa-users"></i> Follow us on WhatsApp</a></li>
          <li><a href="#"><i className="fas fa-thumbs-up"></i> Join our Facebook Group</a></li>
          <li><a href="#"><i className="fas fa-star"></i> Review us on TrustPilot</a></li>
        </ul>
      </div>
      </div>

      {/* Quick Links */}
      
    </div>
  );
};

export default Dashboard;