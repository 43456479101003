import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MessageTemplates.css'; // Optional for custom styling
import { Link } from 'react-router-dom';

const templates = [
    { name: 'wanotifier_team (en)', preview: 'Say Hello! to WANotifier 👋', status: 'APPROVED', created: '10 Apr, 2024', modified: '10 Apr, 2024' },
    { name: 'test_character_limit_templat...', preview: 'Contrary to popular belief, L...', status: 'APPROVED', created: '29 Mar, 2024', modified: '29 Mar, 2024' },
    { name: 'text_template_011_new_011...', preview: 'Hello {{1}}, Thank you for sh...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
    { name: 'lto_02_copy_new', preview: 'Honest feedbacks from som...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
    { name: 'update_lto02_copy', preview: 'Check here!!!', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' }
];

const MessageTemplates = () => {
    return (
        <div className="container mt-4">
            <h3>Message Templates</h3>
            <div className="d-flex mb-3 gap-2">
             <Link to='/add-templates'>  <button className="btn btn-success">Add New</button></Link> 
                <button className="btn btn-outline-primary">Fetch Templates</button>
                <button className="btn btn-outline-secondary">Refresh Statuses</button>
                <input type="text" className="form-control w-25 ms-auto" placeholder="Search..." />
            </div>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>TEMPLATE NAME</th>
                        <th>PREVIEW</th>
                        <th>STATUS</th>
                        <th>CREATED</th>
                        <th>LAST MODIFIED</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {templates.map((template, index) => (
                        <tr key={index}>
                            <td>{template.name}</td>
                            <td>{template.preview}</td>
                            <td>
                                <span className="badge bg-success">{template.status}</span>
                            </td>
                            <td>{template.created}</td>
                            <td>{template.modified}</td>
                            <td>
                                <i className="fas fa-chart-line me-2"></i>
                                <i className="fas fa-edit me-2"></i>
                                <i className="fas fa-trash-alt"></i>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center">
                <span>Showing 1 to 5 of 89 entries</span>
                <nav>
                    <ul className="pagination mb-0">
                        <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" href="#">Next</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default MessageTemplates;
