import React, { useState } from 'react';
import { useTable } from 'react-table';
import SubscriptionPopup from './SubscriptionPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const AccountDetails = () => {

    const [showPopup, setShowPopup] = useState(false);
    // State to hold form data
    const [formData, setFormData] = useState({
        firstName: 'Ram',
        lastName: 'Shengale',
        email: 'ramshengale@gmail.com',
        timezone: 'UTC+5:30',
        websiteUrl: 'https://fantastech.co',
        billingFirstName: 'Ram',
        billingLastName: 'Shengale',
        companyName: '',
        country: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    // Inline styles
    const containerStyle = {
        maxWidth: '1024px',
        margin: '0 auto',
        padding: '1rem',
    };

    const cardStyle = {
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '0.375rem',
        padding: '1.5rem',
    };

    const sectionTitleStyle = {
        fontSize: '1.25rem',
        fontWeight: '600',
        marginBottom: '0.5rem',
    };

    const paragraphStyle = {
        color: '#4A4A4A',
        marginBottom: '1rem',
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1rem',
        '@media (min-width: 768px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    };

    const inputStyle = {
        width: '100%',
        border: '1px solid #D1D5DB',
        borderRadius: '0.375rem',
        padding: '0.5rem 0.75rem',
        marginTop: '0.25rem',
    };

    // const buttonStyle = {
    //     backgroundColor: '#14B8A6',
    //     color: 'white',
    //     padding: '0.5rem 1rem',
    //     borderRadius: '0.375rem',
    //     cursor: 'pointer',
    //     marginTop: '1rem',
    // };

    // const buttonSecondaryStyle = {
    //     color: '#4A4A4A',
    //     padding: '0.5rem 1rem',
    //     cursor: 'pointer',
    //     marginTop: '1rem',
    // };

    const buttonStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        cursor: 'pointer',
      };
      
      const buttonSecondaryStyle = {
        backgroundColor: '#f1f1f1',
        color: '#4A4A4A',
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        cursor: 'pointer',
      };
      
      const buttonActiveStyle = {
        backgroundColor: '#14B8A6', // Active color (teal)
        color: 'white',
        padding: '10px 20px',
        borderRadius: '8px',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold', // Optional to make it look bolder
      };

    // Handle input change for all form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [selectedSection, setSelectedSection] = useState('account'); // Default to 'account'

    // Handle button click to switch sections
    const handleSectionChange = (section) => {
      setSelectedSection(section);
    };
  

    // Handle save changes (e.g., form submission)
    const handleSaveChanges = () => {
        console.log('Form data:', formData);
        // Here, you would typically send `formData` to the server or handle form submission
    };


    const columns = React.useMemo(
        () => [
          {
            Header: 'DISPLAY NAME',
            accessor: 'displayName',
          },
          {
            Header: 'PHONE NUMBER',
            accessor: 'phoneNumber',
          },
          {
            Header: 'PHONE NUMBER ID',
            accessor: 'phoneNumberId',
          },
          {
            Header: 'CONNECTION',
            accessor: 'connection',
          },
          {
            Header: 'STATUS',
            accessor: 'status',
            Cell: ({ value }) => (
              <span className={`px-2 py-1 rounded-md ${value === 'CONNECTED' ? 'bg-green-100 text-green-700' : 'bg-gray-200 text-gray-600'}`}>
                {value}
              </span>
            ),
          },
          {
            Header: 'SUBSCRIPTION',
            accessor: 'subscription',
          },
        ],
        []
      );
    
      // Sample data for WhatsApp numbers (replace with your actual data)
      const data = React.useMemo(
        () => [
          {
            displayName: 'WANotifier Test',
            phoneNumber: '+91 83297 69248',
            phoneNumberId: '110********',
            connection: 'Direct',
            status: 'CONNECTED',
            subscription: 'Startup (Monthly)',
          },
        ],
        []
      );
    
      // Initialize react-table
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      });

    return (
        // <div style={containerStyle}>
        //     <div style={cardStyle}>
        //         <div style={{ display: 'flex', gap: '1rem', marginBottom: '1.5rem' }}>
        //             <button style={buttonStyle}>My Account</button>
        //             <button style={buttonSecondaryStyle}>WhatsApp Numbers</button>
        //             <button style={buttonSecondaryStyle}>My Subscriptions</button>
        //         </div>
                // <div style={{ marginBottom: '1.5rem' }}>
                //     <h2 style={sectionTitleStyle}>Account Details</h2>
                //     <p style={paragraphStyle}>
                //         Details you enter on this page are for internal use like billing, support, etc. and won't be seen by your contacts. 
                //         To edit how your WhatsApp profile looks to your contacts, you can edit that from 
                //         <a href="#" style={{ color: '#14B8A6' }}> Settings</a> page.
                //     </p>
                //     <div style={gridStyle}>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>FIRST NAME *</label>
                //             <input
                //                 type="text"
                //                 name="firstName"
                //                 value={formData.firstName}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>LAST NAME *</label>
                //             <input
                //                 type="text"
                //                 name="lastName"
                //                 value={formData.lastName}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>EMAIL ADDRESS *</label>
                //             <input
                //                 type="email"
                //                 name="email"
                //                 value={formData.email}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>TIMEZONE *</label>
                //             <select
                //                 name="timezone"
                //                 value={formData.timezone}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             >
                //                 <option>UTC+5:30</option>
                //             </select>
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>WEBSITE URL *</label>
                //             <input
                //                 type="url"
                //                 name="websiteUrl"
                //                 value={formData.websiteUrl}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //     </div>
                // </div>
                // <div style={{ marginBottom: '1.5rem' }}>
                //     <h2 style={sectionTitleStyle}>Billing Details</h2>
                //     <div style={gridStyle}>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>FIRST NAME *</label>
                //             <input
                //                 type="text"
                //                 name="billingFirstName"
                //                 value={formData.billingFirstName}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>LAST NAME *</label>
                //             <input
                //                 type="text"
                //                 name="billingLastName"
                //                 value={formData.billingLastName}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>COMPANY NAME (OPTIONAL)</label>
                //             <input
                //                 type="text"
                //                 name="companyName"
                //                 value={formData.companyName}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>COUNTRY / REGION</label>
                //             <input
                //                 type="text"
                //                 name="country"
                //                 value={formData.country}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //     </div>
                // </div>

                // {/* Password Section */}
                // <div>
                //     <h2 style={sectionTitleStyle}>Password</h2>
                //     <div style={gridStyle}>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>CURRENT PASSWORD *</label>
                //             <input
                //                 type="password"
                //                 name="currentPassword"
                //                 value={formData.currentPassword}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>NEW PASSWORD *</label>
                //             <input
                //                 type="password"
                //                 name="newPassword"
                //                 value={formData.newPassword}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //         <div>
                //             <label style={{ color: '#4A4A4A' }}>CONFIRM NEW PASSWORD *</label>
                //             <input
                //                 type="password"
                //                 name="confirmNewPassword"
                //                 value={formData.confirmNewPassword}
                //                 onChange={handleChange}
                //                 style={inputStyle}
                //             />
                //         </div>
                //     </div>
                // </div>

                // {/* Save Changes Button */}
                // <div>
                //     <button style={buttonStyle} onClick={handleSaveChanges}>Save Changes</button>
                // </div>
        //     </div>
        // </div>

        <div style={containerStyle}>
        <div style={cardStyle}>
          {/* Navigation Buttons */}
          <div style={{ display: 'flex', gap: '1rem', marginBottom: '1.5rem' }}>
            <button
              style={selectedSection === 'account' ? buttonActiveStyle : buttonStyle}
              onClick={() => handleSectionChange('account')}
            >
              My Account
            </button>
            <button
              style={selectedSection === 'whatsapp' ? buttonActiveStyle : buttonSecondaryStyle}
              onClick={() => handleSectionChange('whatsapp')}
            >
              WhatsApp Numbers
            </button>
            <button
              style={selectedSection === 'subscriptions' ? buttonActiveStyle : buttonSecondaryStyle}
              onClick={() => handleSectionChange('subscriptions')}
            >
              My Subscriptions
            </button>
          </div>
  
          {/* Account Details Section */}
          {selectedSection === 'account' && (
            <div style={{ marginBottom: '1.5rem' }}>
              <h2 style={sectionTitleStyle}>Account Details</h2>
              <p style={paragraphStyle}>
                Details you enter on this page are for internal use like billing, support, etc. and won't be seen by your contacts.
                To edit how your WhatsApp profile looks to your contacts, you can edit that from 
                <a href="#" style={{ color: '#14B8A6' }}> Settings</a> page.
              </p>
              <div style={gridStyle}>
              <div style={{ marginBottom: '1.5rem' }}>
                    <h2 style={sectionTitleStyle}>Account Details</h2>
                    <p style={paragraphStyle}>
                        Details you enter on this page are for internal use like billing, support, etc. and won't be seen by your contacts. 
                        To edit how your WhatsApp profile looks to your contacts, you can edit that from 
                        <a href="#" style={{ color: '#14B8A6' }}> Settings</a> page.
                    </p>
                    <div style={gridStyle}>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>FIRST NAME *</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>LAST NAME *</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>EMAIL ADDRESS *</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>TIMEZONE *</label>
                            <select
                                name="timezone"
                                value={formData.timezone}
                                onChange={handleChange}
                                style={inputStyle}
                            >
                                <option>UTC+5:30</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>WEBSITE URL *</label>
                            <input
                                type="url"
                                name="websiteUrl"
                                value={formData.websiteUrl}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: '1.5rem' }}>
                    <h2 style={sectionTitleStyle}>Billing Details</h2>
                    <div style={gridStyle}>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>FIRST NAME *</label>
                            <input
                                type="text"
                                name="billingFirstName"
                                value={formData.billingFirstName}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>LAST NAME *</label>
                            <input
                                type="text"
                                name="billingLastName"
                                value={formData.billingLastName}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>COMPANY NAME (OPTIONAL)</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>COUNTRY / REGION</label>
                            <input
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                    </div>
                </div>

                {/* Password Section */}
                <div>
                    <h2 style={sectionTitleStyle}>Password</h2>
                    <div style={gridStyle}>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>CURRENT PASSWORD *</label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={formData.currentPassword}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>NEW PASSWORD *</label>
                            <input
                                type="password"
                                name="newPassword"
                                value={formData.newPassword}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                        <div>
                            <label style={{ color: '#4A4A4A' }}>CONFIRM NEW PASSWORD *</label>
                            <input
                                type="password"
                                name="confirmNewPassword"
                                value={formData.confirmNewPassword}
                                onChange={handleChange}
                                style={inputStyle}
                            />
                        </div>
                    </div>
                </div>

                {/* Save Changes Button */}
                <div>
                    <button style={buttonStyle} onClick={handleSaveChanges}>Save Changes</button>
                </div>
              </div>
            </div>
          )}
  
          {/* WhatsApp Numbers Section */}
          <>
      {selectedSection === 'whatsapp' && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">WhatsApp Phone Numbers</h2>
          </div>
          <p className="text-gray-600 mb-4">
            On this page you'll find all the WhatsApp phone numbers that are connected with WANotifier and the WhatsApp Cloud APIs.
          </p>
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner">
            <div className="d-flex flex-row justify-content-between items-center border-b pb-2 mb-4">
              <div>
                <h3 className="text-lg font-semibold">WANotifier</h3>
                <p className="text-gray-500">
                  WABA ID: 109<span className="bg-gray-200 rounded px-2 py-1 ml-2">********</span>
                </p>
              </div>
              <button className="btn btn-primary" style={{height:40}}>WhatsApp Manager</button>
            </div>

            <table {...getTableProps()} className="w-full text-left">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="text-gray-600">
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()} className="py-2">
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="bg-white border-b">
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className="py-2">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  
          {/* My Subscriptions Section */}
          {selectedSection === 'subscriptions' && (
            <div>
                 <div className="d-flex flex-row justify-content-between items-center border-b pb-2 mb-4">
                 <div>
              <h2 style={sectionTitleStyle}>My Subscriptions</h2>
              </div>
              <button className="btn btn-primary"   onClick={() => setShowPopup(true)}>Buy Subscription</button>
              </div>
              <table className="w-full text-left">
                            <thead>
                                <tr className="text-gray-600">
                                    <th className="py-2">ID</th>
                                    <th className="py-2">STATUS</th>
                                    <th className="py-2">SUBSCRIPTION</th>
                                    <th className="py-2">NEXT PAYMENT</th>
                                    <th className="py-2">TOTAL</th>
                                    <th className="py-2">ASSOCIATED WHATSAPP NUMBER</th>
                                    <th className="py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-t">
                                    <td className="py-2 text-teal-600">#235171</td>
                                    <td className="py-2"><span className="bg-gray-300 text-gray-600 px-2 py-1 rounded">CANCELLED</span></td>
                                    <td className="py-2">WANotifier - Startup (Monthly)</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2">₹2,358.82</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2"><button className="text-teal-600 border border-teal-600 px-4 py-1 rounded">View</button></td>
                                </tr>
                                <tr className="border-t">
                                    <td className="py-2 text-teal-600">#232409</td>
                                    <td className="py-2"><span className="bg-gray-300 text-gray-600 px-2 py-1 rounded">CANCELLED</span></td>
                                    <td className="py-2">WANotifier - Startup (Monthly)</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2">₹2,358.82</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2"><button className="text-teal-600 border border-teal-600 px-4 py-1 rounded">View</button></td>
                                </tr>
                                <tr className="border-t">
                                    <td className="py-2 text-teal-600">#158351</td>
                                    <td className="py-2"><span className="bg-gray-300 text-gray-600 px-2 py-1 rounded">CANCELLED</span></td>
                                    <td className="py-2">WANotifier - Startup (Monthly)</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2">₹23.59</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2"><button className="text-teal-600 border border-teal-600 px-4 py-1 rounded">View</button></td>
                                </tr>
                                <tr className="border-t">
                                    <td className="py-2 text-teal-600">#56217</td>
                                    <td className="py-2"><span className="bg-yellow-400 text-white px-2 py-1 rounded">PENDING CANCELLATION</span></td>
                                    <td className="py-2">WANotifier - Startup (Monthly)</td>
                                    <td className="py-2">-</td>
                                    <td className="py-2">₹23.59</td>
                                    <td className="py-2 text-teal-600">WANotifier Test (+91 83297 69248)</td>
                                    <td className="py-2"><button className="text-teal-600 border border-teal-600 px-4 py-1 rounded">View</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <Popup
            open={showPopup}
            closeOnDocumentClick
            onClose={() => setShowPopup(false)}
            modal
            nested
          >
            {(close) => (
              <div className="relative">
                <SubscriptionPopup onClose={close} />
              </div>
            )}
          </Popup>
            </div>
          )}
        </div>
      </div>
  
    );
};

export default AccountDetails;
