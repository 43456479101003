
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { FaUsers, FaSitemap, FaFileExcel, FaPlus, FaUserAlt, FaMobileAlt, FaRegListAlt, FaTrashAlt, FaEdit } from 'react-icons/fa';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from 'axios';

const AddContactForm = () => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedSubgroup, setSelectedSubgroup] = useState('');
  const [groups, setGroups] = useState({});
  const [newGroup, setNewGroup] = useState('');
  const [newSubgroup, setNewSubgroup] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [groupName, setGroupName] = useState('');
  const [subgroupName, setSubgroupName] = useState('');
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [newName, setNewName] = useState('');
  const [newMobile, setNewMobile] = useState('');
  const [newGroupId, setNewGroupId] = useState('');
  const [newSubgroupId, setNewSubgroupId] = useState('');


  const handleSubgroupChange = (e) => {
    setSelectedSubgroup(e.target.value);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        const data = new Uint8Array(evt.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        const parsedContacts = sheetData.map((row) => {
          const mobileNumber = row.Mobile ? String(row.Mobile).trim() : '';
          const phoneNumber = parsePhoneNumberFromString(mobileNumber, selectedCountry);
          const formattedNumber = phoneNumber ? phoneNumber.formatInternational() : mobileNumber;

          return {
            name: row.Name,
            mobile: formattedNumber,
          };
        });

        // Send the group name, subgroup name (if selected), and contacts to the backend
        axios
          .post('https://chatbotapi.emedha.in/pages/api/upload-contacts', {
            groupName: selectedGroup,
            subgroupName: selectedSubgroup || '', // If no subgroup is selected, send empty string
            contacts: parsedContacts,
          })
          .then((response) => {
            alert('Contacts uploaded successfully');
          })
          .catch((error) => {
            console.error('Error uploading contacts:', error);
            alert('Failed to upload contacts');
          });
      };

      reader.readAsArrayBuffer(file);
      e.target.value = '';
    }
  };


  const handleAddGroup = () => {
    if (newGroup) {
      setGroups((prevGroups) => ({
        ...prevGroups,
        [newGroup]: { contacts: [] },
      }));
      setNewGroup('');
    }
  };

  const handleAddSubgroup = () => {
    if (newSubgroup && selectedGroup) {
      setGroups((prevGroups) => {
        const updatedGroups = { ...prevGroups };
        updatedGroups[selectedGroup] = {
          ...updatedGroups[selectedGroup],
          subgroups: [
            ...(updatedGroups[selectedGroup]?.subgroups || []),
            newSubgroup,
          ],
        };
        return updatedGroups;
      });
      setNewSubgroup('');
    }
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value); // Update selected group
  };


  const fetchContacts = async () => {
    try {
      const url = new URL('https://chatbotapi.emedha.in/pages/api/contacts');
      if (groupName) url.searchParams.append('groupName', groupName);
      if (subgroupName) url.searchParams.append('subgroupName', subgroupName);

      const response = await fetch(url);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch contacts');
      }

      const data = await response.json();
      setContacts(data.contacts);
      setError('');
    } catch (err) {
      setError(err.message);
      setContacts([]);
    }
  };
  const handleEdit = (contact) => {
    console.log('Editing contact:', contact);  
    setIsEditing(true);
    setCurrentContact(contact);
    setNewName(contact.contact_name);
    setNewMobile(contact.contact_mobile);
    setNewGroupId(contact.group_id);
    setNewSubgroupId(contact.subgroup_id);
  };
  
  const handleUpdateContact = async () => {
    if (!currentContact?.id) {  
      alert('Contact ID is missing!');
      return;
    }
  
    try {

      const contactData = {
        name: newName,
        mobile: newMobile,
        groupId: currentContact.groupId, 
        subgroupId: newSubgroupId !== undefined ? newSubgroupId : currentContact.subgroupId, 
      };
  
      const response = await fetch(`https://chatbotapi.emedha.in/pages/api/update-contact/${currentContact.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contactData),
      });
  
      const result = await response.json();
      if (response.ok) {
        alert('Contact updated successfully');
        fetchContacts(); 
        setIsEditing(false); 
      } else {
        alert(result.error || 'Failed to update contact');
      }
    } catch (err) {
      console.error('Error updating contact:', err);
    }
  };
  
  

  const handleDelete = (contactId) => {
    console.log('Delete contact:', contactId);
  };

  const filteredContacts = contacts.filter(contact => {
    const searchLower = searchQuery.toLowerCase();
    return (
      contact.contact_name.toLowerCase().includes(searchLower) ||
      contact.contact_mobile.includes(searchLower)
    );
  });


  return (
    <>
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="card col-6 p-3 shadow-sm rounded">
            <label htmlFor="newGroup" className="form-label fs-5 fw-semibold">
              <FaUsers className="me-2" />
              Create Group
            </label>
            <div className="d-flex align-items-center">
              <input type="text" id="newGroup" className="form-control w-75" value={newGroup} onChange={(e) => setNewGroup(e.target.value)} placeholder="Enter Group Name" />
              <button className="btn btn-primary ms-2 px-4 btn-sm py-1" onClick={handleAddGroup}>
                <FaPlus className="me-2" /> Create
              </button>
            </div>
          </div>
          <div className="col-6">
            <label htmlFor="groupSelect" className="form-label">
              <FaUsers className="me-2" />
              Select Group
            </label>
            <select id="groupSelect" className="form-select" value={selectedGroup} onChange={handleGroupChange}>
              <option value="" disabled>
                Select a Group
              </option>
              {Object.keys(groups).map((groupName, index) => (
                <option key={index} value={groupName}>
                  {groupName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row mb-3">
          {selectedGroup && (
            <div className="card col-6 p-3 shadow-sm rounded">
              <label htmlFor="newSubgroup" className="form-label fs-5 fw-semibold">
                <FaSitemap className="me-2" />
                Create SubGroup
              </label>
              <div className="d-flex align-items-center">
                <input type="text" id="newSubgroup" className="form-control w-75" value={newSubgroup} onChange={(e) => setNewSubgroup(e.target.value)} placeholder="Enter new subgroup name" />
                <button
                  className="btn btn-primary ms-2 px-4 btn-sm py-1"
                  onClick={handleAddSubgroup}
                >
                  <FaPlus className="me-2" /> Add
                </button>
              </div>
            </div>
          )}
          {selectedGroup && (
            <div className="col-6">
              <label htmlFor="subgroupSelect" className="form-label">
                <FaSitemap className="me-2" />
                Select SubGroup
              </label>
              <select id="subgroupSelect" className="form-select" value={selectedSubgroup} onChange={handleSubgroupChange}>
                <option value="" disabled>
                  Select a Subgroup
                </option>
                {groups[selectedGroup]?.subgroups?.map((subgroup, index) => (
                  <option key={index} value={subgroup}>
                    {subgroup}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="countrySelect" className="form-label">
              Select Country Then Choose File
            </label>
            <select id="countrySelect" className="form-select" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              <option value="IN">India (+91)</option>
              <option value="US">United States (+1)</option>
              <option value="GB">United Kingdom (+44)</option>
              <option value="AU">Australia (+61)</option>
            </select>
          </div>
          <div className="col-6">
            <label htmlFor="contactFile" className="form-label">
              <FaFileExcel className="me-2" />
              Upload Contacts (Excel)
            </label>
            <input
              type="file"
              className="form-control"
              id="contactFile"
              accept=".xlsx,.xls"
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </div> 
      <div className="container mt-5">
      <h3 className="mb-4">CONTACTS</h3>

      <div className="mb-4">
        <div className="row">
          <div className="col-6">
            <input type="text" value={groupName} onChange={(e) => setGroupName(e.target.value)} className="form-control mb-2" placeholder="Enter Group Name" />
          </div>
          <div className="col-6">
            <input type="text" value={subgroupName} onChange={(e) => setSubgroupName(e.target.value)} className="form-control mb-2" placeholder="Subgroup Name" />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-6">
              <button onClick={fetchContacts} className="btn btn-primary mt-2">Load Contacts</button>
            </div>
            <div className="col-6">
              <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control" placeholder="Search by Name or Mobile" />
            </div>
          </div>
        </div>
      </div>

      {error && <p className="text-danger">{error}</p>}

      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th><FaUserAlt /> Contact Name</th>
            <th><FaMobileAlt /> Mobile</th>
            <th><FaUsers /> Group</th>
            <th><FaRegListAlt /> Subgroup</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredContacts.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">No contacts found</td>
            </tr>
          ) : (
            filteredContacts.map((contact, index) => (
              <tr key={index}>
                <td>{contact.contact_name}</td>
                <td>{contact.contact_mobile}</td>
                <td>{contact.group_name}</td>
                <td>{contact.subgroup_name || 'No Subgroup'}</td>
                <td>
                  <button className="btn btn-warning btn-sm" onClick={() => handleEdit(contact)}><FaEdit /> </button>
                  <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(contact.contact_id)}><FaTrashAlt /> </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isEditing && (
        <div className="mt-4">
          <h4>Edit Contact</h4>
          <div>
            <input 
              type="text" 
              value={newName} 
              onChange={(e) => setNewName(e.target.value)} 
              className="form-control mb-2" 
              placeholder="Contact Name" 
            />
            <input 
              type="text" 
              value={newMobile} 
              onChange={(e) => setNewMobile(e.target.value)} 
              className="form-control mb-2" 
              placeholder="Mobile" 
            />
            <button onClick={handleUpdateContact} className="btn btn-primary btn-sm mt-2">Update</button>
            <button onClick={() => setIsEditing(false)} className="btn btn-secondary btn-sm mt-2 ms-2">Cancel</button>
          </div>
        </div>
      )}
    </div>  
    </>
  );
};

export default AddContactForm;




// import React, { useState } from 'react';
// import { FaEdit, FaTrashAlt, FaMobileAlt, FaRegListAlt, FaUserAlt, FaUsers } from 'react-icons/fa';

// const ContactsPage = () => {
//   const [groupName, setGroupName] = useState('');
//   const [subgroupName, setSubgroupName] = useState('');
//   const [contacts, setContacts] = useState([]);
//   const [error, setError] = useState('');
//   const [searchQuery, setSearchQuery] = useState('');
//   const [isEditing, setIsEditing] = useState(false);
//   const [currentContact, setCurrentContact] = useState(null);
//   const [newName, setNewName] = useState('');
//   const [newMobile, setNewMobile] = useState('');
//   const [newGroupId, setNewGroupId] = useState('');
//   const [newSubgroupId, setNewSubgroupId] = useState('');

//   const fetchContacts = async () => {
//     try {
//       const url = new URL('https://chatbotapi.emedha.in/pages/api/contacts');
//       if (groupName) url.searchParams.append('groupName', groupName);
//       if (subgroupName) url.searchParams.append('subgroupName', subgroupName);

//       const response = await fetch(url);

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || 'Failed to fetch contacts');
//       }

//       const data = await response.json();
//       setContacts(data.contacts);
//       setError('');
//     } catch (err) {
//       setError(err.message);
//       setContacts([]);
//     }
//   };
//   const handleEdit = (contact) => {
//     console.log('Editing contact:', contact);  
//     setIsEditing(true);
//     setCurrentContact(contact);
//     setNewName(contact.contact_name);
//     setNewMobile(contact.contact_mobile);
//     setNewGroupId(contact.group_id);
//     setNewSubgroupId(contact.subgroup_id);
//   };
  
//   const handleUpdateContact = async () => {
//     if (!currentContact?.id) {  
//       alert('Contact ID is missing!');
//       return;
//     }
  
//     try {

//       const contactData = {
//         name: newName,
//         mobile: newMobile,
//         groupId: currentContact.groupId, 
//         subgroupId: newSubgroupId !== undefined ? newSubgroupId : currentContact.subgroupId, 
//       };
  
//       const response = await fetch(`https://chatbotapi.emedha.in/pages/api/update-contact/${currentContact.id}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(contactData),
//       });
  
//       const result = await response.json();
//       if (response.ok) {
//         alert('Contact updated successfully');
//         fetchContacts(); 
//         setIsEditing(false); 
//       } else {
//         alert(result.error || 'Failed to update contact');
//       }
//     } catch (err) {
//       console.error('Error updating contact:', err);
//     }
//   };
  
  

//   const handleDelete = (contactId) => {
//     console.log('Delete contact:', contactId);
//   };

//   const filteredContacts = contacts.filter(contact => {
//     const searchLower = searchQuery.toLowerCase();
//     return (
//       contact.contact_name.toLowerCase().includes(searchLower) ||
//       contact.contact_mobile.includes(searchLower)
//     );
//   });

//   return (
//     <div className="container mt-5">
//       <h3 className="mb-4">CONTACTS</h3>

//       <div className="mb-4">
//         <div className="row">
//           <div className="col-6">
//             <input type="text" value={groupName} onChange={(e) => setGroupName(e.target.value)} className="form-control mb-2" placeholder="Group Name" />
//           </div>
//           <div className="col-6">
//             <input type="text" value={subgroupName} onChange={(e) => setSubgroupName(e.target.value)} className="form-control mb-2" placeholder="Subgroup Name" />
//           </div>
//         </div>
//         <div>
//           <div className="row">
//             <div className="col-6">
//               <button onClick={fetchContacts} className="btn btn-primary mt-2">Load Contacts</button>
//             </div>
//             <div className="col-6">
//               <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control" placeholder="Search by Name or Mobile" />
//             </div>
//           </div>
//         </div>
//       </div>

//       {error && <p className="text-danger">{error}</p>}

//       <table className="table table-striped table-bordered">
//         <thead>
//           <tr>
//             <th><FaUserAlt /> Contact Name</th>
//             <th><FaMobileAlt /> Mobile</th>
//             <th><FaUsers /> Group</th>
//             <th><FaRegListAlt /> Subgroup</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredContacts.length === 0 ? (
//             <tr>
//               <td colSpan="5" className="text-center">No contacts found</td>
//             </tr>
//           ) : (
//             filteredContacts.map((contact, index) => (
//               <tr key={index}>
//                 <td>{contact.contact_name}</td>
//                 <td>{contact.contact_mobile}</td>
//                 <td>{contact.group_name}</td>
//                 <td>{contact.subgroup_name || 'No Subgroup'}</td>
//                 <td>
//                   <button className="btn btn-warning btn-sm" onClick={() => handleEdit(contact)}><FaEdit /> </button>
//                   <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(contact.contact_id)}><FaTrashAlt /> </button>
//                 </td>
//               </tr>
//             ))
//           )}
//         </tbody>
//       </table>

//       {isEditing && (
//         <div className="mt-4">
//           <h4>Edit Contact</h4>
//           <div>
//             <input 
//               type="text" 
//               value={newName} 
//               onChange={(e) => setNewName(e.target.value)} 
//               className="form-control mb-2" 
//               placeholder="Contact Name" 
//             />
//             <input 
//               type="text" 
//               value={newMobile} 
//               onChange={(e) => setNewMobile(e.target.value)} 
//               className="form-control mb-2" 
//               placeholder="Mobile" 
//             />
//             <button onClick={handleUpdateContact} className="btn btn-primary mt-2">Update</button>
//             <button onClick={() => setIsEditing(false)} className="btn btn-secondary mt-2 ms-2">Cancel</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ContactsPage;





