import React, { useState } from 'react';

const AutoReplies = () => {
  const [replies, setReplies] = useState([
    { id: 1, trigger: 'HELP', message: 'Message template: final_custome_copy', status: false },
    { id: 2, trigger: 'NOT INTRESTED', message: 'Message template: wanotifier_team', status: false },
    { id: 3, trigger: 'INTRESTED', message: 'Text message: WELCOME TO OUR TEAM!!!!!', status: true },
    { id: 4, trigger: 'CONTACT US', message: 'Message template: newthings_template01', status: false },
    { id: 5, trigger: 'Call', message: 'Text message: Nope!!!', status: true },
    { id: 6, trigger: 'Action', message: 'Text message: How Can I help you????', status: true },
    { id: 7, trigger: 'Hello', message: 'Message template: hello_world', status: true },
    { id: 8, trigger: 'Hi', message: 'Text message: Hello there!!!', status: true },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ trigger: '', message: '', id: null });
  const [isEditing, setIsEditing] = useState(false);

  const handleStatusChange = (id) => {
    setReplies(replies.map(reply =>
      reply.id === id ? { ...reply, status: !reply.status } : reply
    ));
  };

  const handleEdit = (id) => {
    const reply = replies.find((reply) => reply.id === id);
    setFormData({ trigger: reply.trigger, message: reply.message, id: reply.id });
    setIsEditing(true);
    setShowModal(true);
  };

  const handleAddNew = () => {
    setFormData({ trigger: '', message: '', id: null });
    setIsEditing(false);
    setShowModal(true);
  };

  const handleSubmit = () => {
    if (isEditing) {
      // Edit existing reply
      setReplies(replies.map(reply =>
        reply.id === formData.id ? { ...reply, trigger: formData.trigger, message: formData.message } : reply
      ));
    } else {
      // Add new reply
      setReplies([
        ...replies,
        { id: replies.length + 1, trigger: formData.trigger, message: formData.message, status: false }
      ]);
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Inline styles
  const containerStyle = {
    maxWidth: '1000px',
    margin: '20px auto',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const buttonStyle = {
    backgroundColor: '#00bfa5',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    padding: '15px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    backgroundColor: '#f9f9f9',
  };

  const tdStyle = {
    padding: '15px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };

  const statusSwitchStyle = {
    position: 'relative',
    display: 'inline-block',
    width: '34px',
    height: '20px',
  };

  const sliderStyle = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: '#ccc',
    transition: '.4s',
    borderRadius: '34px',
  };

  const sliderBeforeStyle = {
    position: 'absolute',
    content: '""',
    height: '14px',
    width: '14px',
    left: '3px',
    bottom: '3px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%',
  };

  const actionsStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const iconStyle = {
    cursor: 'pointer',
    marginRight: '10px',
  };

  const iconDeleteStyle = {
    color: '#dc3545',
  };

  const proTipStyle = {
    textAlign: 'right',
    color: '#007bff',
    cursor: 'pointer',
  };

  const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '500px',
    width: '100%',
  };

  const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const modalInputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: '1px solid #ddd',
  };

  const modalButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#00bfa5',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h1>Auto-replies</h1>
        <button
          style={buttonStyle}
          onClick={handleAddNew}
        >
          Add New
        </button>
      </div>
      <p>Automatically send reply messages to your contacts when they send you a message.</p>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>WHEN CUSTOMER SENDS YOU MESSAGE WITH TEXT...</th>
            <th style={thStyle}>AUTO-REPLY THEM WITH...</th>
            <th style={thStyle}>STATUS</th>
            <th style={thStyle}>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {replies.map(reply => (
            <tr key={reply.id}>
              <td style={tdStyle}>{reply.trigger}</td>
              <td style={tdStyle}>{reply.message}</td>
              <td style={tdStyle}>
                <label style={statusSwitchStyle}>
                  <input
                    type="checkbox"
                    checked={reply.status}
                    onChange={() => handleStatusChange(reply.id)}
                    style={{ opacity: 0, width: 0, height: 0 }}
                  />
                  <span style={sliderStyle}>
                    <span
                      style={{
                        ...sliderBeforeStyle,
                        transform: reply.status ? 'translateX(14px)' : 'translateX(0)',
                        backgroundColor: reply.status ? '#4caf50' : '#ccc',
                      }}
                    ></span>
                  </span>
                </label>
              </td>
              <td style={tdStyle}>
                <i
                  className="fas fa-edit"
                  style={iconStyle}
                  onClick={() => handleEdit(reply.id)}
                ></i>
                <i
                  className="fas fa-trash-alt"
                  style={iconDeleteStyle}
                  onClick={() => setReplies(replies.filter(r => r.id !== reply.id))}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    

      {/* Modal for Editing or Adding New Auto-reply */}
      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <div style={modalHeaderStyle}>
              <h3>{isEditing ? 'Edit Auto-reply' : 'Add New Auto-reply'}</h3>
              <button
                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                onClick={handleCancel}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <input
              type="text"
              name="trigger"
              value={formData.trigger}
              onChange={handleInputChange}
              placeholder="Trigger Text"
              style={modalInputStyle}
            />
          
              <input
              type="text"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Auto-reply Message"
              style={modalInputStyle}
            />
            <div>
              <button
                style={modalButtonStyle}
                onClick={handleSubmit}
              >
                {isEditing ? 'Update Reply' : 'Add Reply'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoReplies;
