import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ImportExportContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const contactLimit = 10000; // Replace with backend value later

  // ✅ Handle File Selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // ✅ Handle Import CSV
  const handleImportCSV = () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setContacts(jsonData);
      console.log('Imported Contacts:', jsonData);
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  // ✅ Handle Export CSV
  const handleExportCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts.length ? contacts : sampleContacts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
    XLSX.writeFile(workbook, 'contacts.xlsx');
  };

  // ✅ Handle Save Contacts
  const handleSaveContacts = () => {
    console.log('Saving Contacts:', contacts);
    alert('Contacts saved successfully!');
    // Add your backend API call here to save contacts
  };

  // Sample contacts for export
  const sampleContacts = [
    {
      Name: 'Sweta Funde',
      Phone: '+91878116960',
      Status: 'SUBSCRIBED',
      List: 'Hello 1, Hello 3',
      Tags: 'new, new lead',
      Created: '17 Jul, 2023',
      Modified: '11 Apr, 2024',
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      {/* Import/Export Section */}
      <div style={{ display: 'flex', gap: '20px', marginBottom: '30px' }}>
        {/* Import Contacts */}
        <div style={{ flex: 2, backgroundColor: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Import Contacts Using CSV File</h3>
          <p>Maximum contacts limit as per your current plan: <strong>{contactLimit} contacts</strong></p>
          <input
            type="file"
            accept=".csv,.xlsx"
            style={{ margin: '10px 0', padding: '8px', width: '80%' }}
            onChange={handleFileChange}
          />
          <button
            onClick={handleImportCSV}
            style={{
              marginTop: '10px',
              padding: '8px 16px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Import
          </button>
        </div>

        {/* Export Contacts */}
        <div style={{ flex: 1, backgroundColor: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Export Contacts</h3>
          <button
            style={{
              marginTop: '10px',
              padding: '8px 16px',
              backgroundColor: '#009688',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
            }}
            onClick={handleExportCSV}
          >
            Export CSV
          </button>
        </div>
      </div>

      {/* Table to Display Imported Contacts */}
      {contacts.length > 0 && (
        <div style={{ marginTop: '30px', backgroundColor: '#fff', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Imported Contacts</h3>
          <div style={{ overflowX: 'auto', marginTop: '10px' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead>
                <tr>
                  {Object.keys(contacts[0]).map((key) => (
                    <th key={key} style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => (
                  <tr key={index}>
                    {Object.values(contact).map((value, i) => (
                      <td key={i} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                        {value || '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={handleSaveContacts}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Save Contacts
          </button>
        </div>
      )}
    </div>
  );
};

export default ImportExportContacts;
