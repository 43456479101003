import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';  // Import Chart.js
import { Card, Table, Row, Col, Container } from 'react-bootstrap';  // Bootstrap components
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// ConversationsCard Component
const ConversationsCard = ({ data }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>
          Conversations & Approx Cost <i className="fas fa-info-circle"></i>
        </Card.Title>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Conversations</th>
              <th>Free <i className="fas fa-info-circle"></i></th>
              <th>Paid <i className="fas fa-info-circle"></i></th>
              <th>Total <i className="fas fa-info-circle"></i></th>
              <th>Approx Cost <i className="fas fa-info-circle"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Business-initiated <i className="fas fa-info-circle"></i></td>
              <td>{data.businessInitiatedFree}</td>
              <td>{data.businessInitiatedPaid}</td>
              <td>{data.businessInitiatedTotal}</td>
              <td>₹ {data.businessInitiatedCost}</td>
            </tr>
            <tr>
              <td>User-initiated <i className="fas fa-info-circle"></i></td>
              <td>{data.userInitiatedFree}</td>
              <td>{data.userInitiatedPaid}</td>
              <td>{data.userInitiatedTotal}</td>
              <td>₹ {data.userInitiatedCost}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

// ChartCard Component
const ChartCard = ({ title, data, type }) => {
  const chartData = {
    labels: data.labels,
    datasets: data.datasets,
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>
          {title} <i className="fas fa-info-circle"></i>
        </Card.Title>
        {type === 'bar' ? (
          <Bar data={chartData} />
        ) : (
          <Line data={chartData} />
        )}
      </Card.Body>
    </Card>
  );
};

// Messaging Component (Main Component)
const Messaging = () => {
  const conversationsData = {
    businessInitiatedFree: 0,
    businessInitiatedPaid: 22,
    businessInitiatedTotal: 22,
    businessInitiatedCost: '11.38',
    userInitiatedFree: 14,
    userInitiatedPaid: 0,
    userInitiatedTotal: 14,
    userInitiatedCost: '0.00',
  };

  const barChartData = {
    labels: ['01 Apr 2024', '02 Apr 2024', '03 Apr 2024', '04 Apr 2024', '05 Apr 2024', '06 Apr 2024', '07 Apr 2024', '08 Apr 2024', '09 Apr 2024', '10 Apr 2024', '11 Apr 2024', '12 Apr 2024'],
    datasets: [
      {
        label: 'Service',
        backgroundColor: '#4e73df',
        data: [6, 4, 5, 3, 2, 4, 5, 3, 2, 4, 5, 3],
      },
      {
        label: 'Utility',
        backgroundColor: '#1cc88a',
        data: [2, 3, 4, 2, 3, 4, 2, 3, 4, 2, 3, 4],
      },
      {
        label: 'Marketing',
        backgroundColor: '#36b9cc',
        data: [3, 2, 3, 4, 5, 2, 3, 4, 5, 2, 3, 4],
      },
    ],
  };

  const lineChartData = {
    labels: ['01 Apr 2024', '02 Apr 2024', '03 Apr 2024', '04 Apr 2024', '05 Apr 2024', '06 Apr 2024', '07 Apr 2024', '08 Apr 2024', '09 Apr 2024', '10 Apr 2024', '11 Apr 2024', '12 Apr 2024'],
    datasets: [
      {
        label: 'Sent',
        borderColor: '#4e73df',
        data: [40, 45, 50, 30, 35, 40, 45, 50, 30, 35, 40, 45],
        fill: false,
      },
      {
        label: 'Delivered',
        borderColor: '#1cc88a',
        data: [30, 35, 40, 25, 30, 35, 40, 45, 25, 30, 35, 40],
        fill: false,
      },
    ],
  };


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false); // For toggling the start date picker
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false); // For toggling the end date picker

  // Toggle between date pickers based on user's action
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setIsStartDatePickerOpen(false); // Close start date picker after selection
    setIsEndDatePickerOpen(true); // Open end date picker
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndDatePickerOpen(false); // Close end date picker after selection
  };



  return (
    <Container fluid className="mt-4">
     <Row className="mb-4">
        <Col>
          <h5>Messaging Analytics</h5>
          <div>SELECT DATE RANGE</div>
          <div>
            {/* Start Date Picker */}
            
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Start Date"
              open={isStartDatePickerOpen} // Control if the picker is open
              onClickOutside={() => setIsStartDatePickerOpen(false)} // Close picker if clicking outside
              onFocus={() => setIsStartDatePickerOpen(true)} // Open picker on focus
            />
            <span> to </span>
            {/* End Date Picker */}
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="End Date"
              open={isEndDatePickerOpen} // Control if the picker is open
              onClickOutside={() => setIsEndDatePickerOpen(false)} // Close picker if clicking outside
              onFocus={() => setIsEndDatePickerOpen(true)} // Open picker on focus
            />
          </div>
        </Col>
      </Row>
      <div>
        Selected Date Range: {startDate && `From: ${startDate.toLocaleString()}`} 
        {endDate && ` To: ${endDate.toLocaleString()}`}
      </div>
      <Row>
        <Col md={8}>
          <ConversationsCard data={conversationsData} />
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                Messaging <i className="fas fa-info-circle"></i>
              </Card.Title>
              <p>Messages Sent:</p>
              <div className="value">316</div>
              <p>Messages Delivered:</p>
              <div className="value">316</div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ChartCard title="All conversations" data={barChartData} type="bar" />
        </Col>
        <Col md={6}>
          <ChartCard title="All messages" data={lineChartData} type="line" />
        </Col>
      </Row>
    </Container>
  );
};

export default Messaging;
