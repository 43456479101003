import React, { useState } from "react";

// Import a simple emoji picker library or use custom emoji array
import Picker from 'emoji-picker-react'; // You can also create a custom emoji array.

const Inbox = () => {
  const [isAttachmentMenuActive, setAttachmentMenuActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const userData = {
    "Test User": {
        phone: "+917826899878",
        email: "testuser@example.com",
        address: "123 Test Street, City",
        tags: ["new lead", "priority"],
        createdAt: "2023-01-01",
        lastModified: "2023-02-15",
      },
      "Sweta Funde": {
        phone: "+919876543210",
        email: "sweta@example.com",
        address: "456 Sweta Lane, City",
        tags: ["new lead", "active"],
        createdAt: "2023-02-20",
        lastModified: "2023-03-10",
      },
      "John Doe": {
        phone: "+912345678901",
        email: "john.doe@example.com",
        address: "789 John Street, City",
        tags: ["prospective", "follow-up"],
        createdAt: "2023-04-12",
        lastModified: "2023-05-10",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
      "Alice Smith": {
        phone: "+918765432109",
        email: "alice.smith@example.com",
        address: "101 Alice Avenue, City",
        tags: ["VIP", "important"],
        createdAt: "2023-03-01",
        lastModified: "2023-06-25",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
      "Raj Kumar": {
        phone: "+919054321987",
        email: "raj.kumar@example.com",
        address: "202 Raj Plaza, City",
        tags: ["new lead", "consultation"],
        createdAt: "2023-05-15",
        lastModified: "2023-07-04",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
      "Raj Kumar": {
        phone: "+919054321987",
        email: "raj.kumar@example.com",
        address: "202 Raj Plaza, City",
        tags: ["new lead", "consultation"],
        createdAt: "2023-05-15",
        lastModified: "2023-07-04",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
      "Nina Patel": {
        phone: "+919876543210",
        email: "nina.patel@example.com",
        address: "303 Nina Park, City",
        tags: ["ongoing", "priority"],
        createdAt: "2023-06-10",
        lastModified: "2023-07-18",
      },
      "David Williams": {
        phone: "+917654321098",
        email: "david.williams@example.com",
        address: "404 David Lane, City",
        tags: ["completed", "customer"],
        createdAt: "2023-04-25",
        lastModified: "2023-08-02",
      },
    // Add other users here...
  };

  const users = Object.keys(userData);

  const toggleAttachmentMenu = () => {
    setAttachmentMenuActive((prev) => !prev);
  };

  const handleUserClick = (userName) => {
    setSelectedUser(userName);
    setMessages([]);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: newMessage, sender: "me", type: "text" },
    ]);
    setNewMessage("");
  };

  const handleReceivedMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { ...message, sender: "other" },
    ]);
  };

  const handleSendFile = (file) => {
    const fileType = file.type.split("/")[0]; // Get type (audio, video, document, image)
    const fileMessage = {
      text: `${fileType} file sent`,
      sender: "me",
      type: fileType,
      file,
    };
    setMessages((prevMessages) => [...prevMessages, fileMessage]);
  };

  const handleEmojiClick = (event, emojiObject) => {
    setNewMessage(newMessage + emojiObject.emoji);
  };

  const messageStyle = (sender) => ({
    maxWidth: "60%",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: sender === "me" ? "#dcf8c6" : "#fff",
    marginLeft: sender === "me" ? "auto" : "0",
    marginBottom: "10px",
  });

  const renderMessageContent = (msg) => {
    if (msg.type === "text") {
      return msg.text;
    } else if (msg.type === "image") {
      return <img src={URL.createObjectURL(msg.file)} alt="Sent" style={{ maxWidth: "100%", borderRadius: "8px" }} />;
    } else if (msg.type === "audio") {
      return (
        <audio controls>
          <source src={URL.createObjectURL(msg.file)} type={msg.file.type} />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (msg.type === "video") {
      return (
        <video controls style={{ width: "100%", borderRadius: "8px" }}>
          <source src={URL.createObjectURL(msg.file)} type={msg.file.type} />
          Your browser does not support the video element.
        </video>
      );
    } else if (msg.type === "application") {
      return (
        <a href={URL.createObjectURL(msg.file)} download>
          Download {msg.file.name}
        </a>
      );
    }
  };

  const containerStyle = {
    display: "flex",
    width: "100%",
  };

  const sidebarStyle = {
    width: "25%",
    backgroundColor: "#fff",
    borderRight: "1px solid #ddd",
    display: "flex",
    flexDirection: "column",
  };


  const contactListStyle = {
    flex: 1,
    overflowY: "auto",
    overflowY:'scroll',
    height:'100vh',
  };

  const contactItemStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    cursor: "pointer",
  };

  const chatSectionStyle = {
    width: "50%",
    height:'100vh',
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e5ddd5",
  };

  const chatHeaderStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
  };

  const chatMessagesStyle = {
    flex: 1,
    padding: "10px",
    overflowY: "auto",
  };



  const attachmentMenuStyle = {
    position: "absolute",
    bottom: "50px",
    left: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    display: isAttachmentMenuActive ? "flex" : "none",
    flexDirection: "column",
  };

  const menuItemStyle = {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const detailsSectionStyle = {
    width: "25%",
    backgroundColor: "#fff",
    borderLeft: "1px solid #ddd",
    display: "flex",
    alignItems:'center',
    flexDirection: "column",
    padding:'10px'
  };

  const detailsHeaderStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
  };

  const detailsContentStyle = {
    flex: 1,
    padding: "10px",
    overflowY: "auto",
  };

  const fileInputStyle = {
    display: "none",
  };

  const chatInputStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#fff",
    borderTop: "1px solid #ddd",
    position: "relative",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #ddd", // Border around the input and icons
  };
  
  const inputWrapperStyle = {
    display: "flex",
    alignItems: "center",
    width: "85%", // For the input to take the majority of space
  };
  
  const inputStyle = {
    width: "100%", // Ensure the input takes full width
    padding: "8px",
    border: "none",
    outline: "none",
    fontSize: "14px",
    borderRadius: "8px", // Rounded corners
    marginRight: "8px", // Space between the input and the icons
  };
  
  const iconStyle = {
    fontSize: "18px",
    color: "#888", // Icon color
    cursor: "pointer",
    padding: "6px",
    borderRadius: "50%",
    transition: "background-color 0.3s",
  };
  
  const iconHoverStyle = {
    backgroundColor: "#f0f0f0", // Add hover effect on icons
  };
  

  return (
    <div style={containerStyle}>
      <div style={sidebarStyle}>
        <input placeholder="Search contacts and messages" type="text" style={inputStyle} />
        <div style={contactListStyle}>
          {users.map((userName) => (
            <div
              key={userName}
              style={contactItemStyle}
              onClick={() => handleUserClick(userName)}
            >
              <img alt="Contact" src="https://placehold.co/40x40" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px" }} />
              <div className="d-flex flex-column">
                <span style={{color: "#007BFF"}}>{userName}</span>
                <span style={{ color: "#888" }}>Sample message</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedUser && (
        <div style={chatSectionStyle}>
          <div style={chatHeaderStyle}>
            <img alt="User" src="https://placehold.co/40x40" />
            <div>
              <div style={{color: "#007BFF"}}>{selectedUser}</div>
              <div>{userData[selectedUser].phone}</div>
            </div>
          </div>

          <div style={chatMessagesStyle}>
            {messages.map((msg, index) => (
              <div key={index} style={messageStyle(msg.sender)}>
                {renderMessageContent(msg)}
              </div>
            ))}
          </div>

          <div style={chatInputStyle}>
          

            {isEmojiPickerVisible && (
              <div style={{ position: "absolute", bottom: "50px", left: "10px" }}>
                <Picker onEmojiClick={handleEmojiClick} />
              </div>
            )}

<div style={chatInputStyle}>
  <div style={inputWrapperStyle}>
  <i className="fas fa-smile" onClick={() => setEmojiPickerVisible(!isEmojiPickerVisible)}></i>
    <i 
      className="fas fa-paperclip" 
      onClick={toggleAttachmentMenu} 
      style={{...iconStyle, ...iconHoverStyle}} 
    ></i>
    <input
      placeholder="Type a message"
      type="text"
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      style={inputStyle}
    />
  </div>
  <i 
    className="fas fa-paper-plane" 
    onClick={handleSendMessage} 
    style={{...iconStyle, ...iconHoverStyle}} 
  ></i>
</div>


            <div style={attachmentMenuStyle}>
              <div style={menuItemStyle}>
                <label>
                  <i className="fas fa-image"></i> Image
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleSendFile(e.target.files[0])}
                    style={fileInputStyle}
                  />
                </label>
              </div>
              <div style={menuItemStyle}>
                <label>
                  <i className="fas fa-video"></i> Video
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleSendFile(e.target.files[0])}
                    style={fileInputStyle}
                  />
                </label>
              </div>
              <div style={menuItemStyle}>
                <label>
                  <i className="fas fa-microphone"></i> Audio
                  <input
                    type="file"
                    accept="audio/*"
                    onChange={(e) => handleSendFile(e.target.files[0])}
                    style={fileInputStyle}
                  />
                </label>
              </div>
              <div style={menuItemStyle}>
                <label>
                  <i className="fas fa-file"></i> Document
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx,.txt,.xls,.ppt"
                    onChange={(e) => handleSendFile(e.target.files[0])}
                    style={fileInputStyle}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedUser && (
       <div className="detailsSectionStyle p-3">
  
        <div style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          borderBottom: "1px solid #f0f0f0",
          paddingBottom: "15px"
        }}>
          <img alt="User" src="https://placehold.co/40x40" style={{
            borderRadius: "50%",
            marginRight: "15px"
          }} />
          <div>
            <div style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#007BFF"
            }}>
              {selectedUser}
            </div>
            <div style={{
              fontSize: "14px",
              color: "#888"
            }}>
              {userData[selectedUser].phone}
            </div>
          </div>
        </div>
      
        <div style={{
          display: "flex",
          flexDirection: "column", // Set the layout to column-wise
          gap: "20px" ,
          borderBottom:'1px solid #fff'// Add space between sections
        }}>
          {/* Contact Info Section */}
          <div>
            <h3 style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "10px",
              color: "#555",
            
            }}>
              <i className="fa fa-envelope" style={{
                marginRight: "8px",
                color: "#28a745"
              }}></i>
              Contact Info
            </h3>
            <p style={{ fontSize: "14px", marginBottom: "8px" }}>
              <strong>Email:</strong> {userData[selectedUser].email}
            </p>
            <p style={{ fontSize: "14px" }}>
              <strong>Address:</strong> {userData[selectedUser].address}
            </p>
          </div>
      
          {/* Details Section */}
          <div>
            <h3 style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "10px",
              color: "#555"
            }}>
              <i className="fa fa-info-circle" style={{
                marginRight: "8px",
                color: "#ffc107"
              }}></i>
              Details
            </h3>
            <p style={{ fontSize: "14px", marginBottom: "8px" }}>
              <strong>Created on:</strong> {userData[selectedUser].createdAt}
            </p>
            <p style={{ fontSize: "14px" }}>
              <strong>Last Modified:</strong> {userData[selectedUser].lastModified}
            </p>
          </div>
      
          {/* Tags Section */}
          <div>
            <h3 style={{
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "10px",
              color: "#555"
            }}>
              <i className="fa fa-tags" style={{
                marginRight: "8px",
                color: "#007bff"
              }}></i>
              Tags
            </h3>
            <p style={{ fontSize: "14px" }}>
              {userData[selectedUser].tags.join(", ")}
            </p>
          </div>
        </div>
      </div>
      

      )}
    </div>
  );
};

export default Inbox;
