
// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';  
// import 'bootstrap/dist/css/bootstrap.min.css'; 

// const Sidebar = () => {
//   const [openMenu, setOpenMenu] = useState(null); 
//   const navigate = useNavigate(); 

//   const menuItems = [
//     { path: '/', icon: 'fa-tachometer-alt', label: 'Dashboard' },
//     { 
//       path: '/message-templates', 
//       icon: 'fa-envelope', 
//       label: 'Message Templates',
//       children: [
//         { path: '/message-templates', label: 'Templates' },
//         { path: '/add-templates', label: 'ADD Templates' }
//       ]
//     },
//     { path: '/contacts', icon: 'fa-address-book', label: 'Contacts',
//       children: [
//         { path: '/contacts', label: 'contacts' },
//         { path: '/Add-new', label: 'Add New' },
//         { path: '/list-tags', label: 'List&Tags' },
//         { path: '/import-export', label: 'Import/Export' }
//       ]
//     },
//     { 
//       path: '/notifications', 
//       icon: 'fa-bell', 
//       label: 'Notifications',
//       children: [
//         { path: '/notifications', label:  'Notifications' },
//         { path: '/add-notification', label: 'Add New' },
//       ]
//     },
//     { path: '/analytics', icon: 'fa-chart-line', label: 'Analytics',
//       children: [
//         { path: '/messaging', label: 'Messaging' },
//         { path: '/notificationss', label: 'Notifications' }, 
//         { path: '/message-templates-analytics', label: 'Message Templates' },
//       ]
//     },
//     { path: '/automations', icon: 'fa-cogs', label: 'Automations',
//       children: [
//         { path: '/basic', label: 'Basic' },
//         { path: '/auto-replies', label: 'Auto Replies' },
//       ]
//     },
//     { path: '/inbox', icon: 'fa-inbox', label: 'Inbox' ,
//       children: [
//         { path: '/inbox', label: 'Inbox' },
//       ]
//     },
//   ];

//   const toggleSubMenu = (index) => {
//     setOpenMenu(openMenu === index ? null : index);
//   };

//   const sidebarStyle = {
//     width: '250px',
//     height: '100vh',
//     backgroundColor: '#075E54',
//     color: 'white',
//     display: 'flex',
//     flexDirection: 'column',
//     padding: '20px'
//   };

//   const logoStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '30px'
//   };

//   const menuItemStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     padding: '10px',
//     color: 'white',
//     textDecoration: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer'
//   };

//   const submenuStyle = {
//     paddingLeft: '20px',
//     display: 'flex',
//     flexDirection: 'column'
//   };

//   const menuItemHoverStyle = {
//     backgroundColor: '#495057'
//   };

//   // Profile Section Style
//   const profileSectionStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     padding: '10px',
//     marginBottom: '20px',
//     cursor: 'pointer',
  
//     borderRadius: '5px',
//   };

//   const profileTextStyle = {
//     fontSize: '1rem',
//     fontWeight: 'bold',
//     marginLeft: '10px',
//   };

//   return (
//     <div style={sidebarStyle}>
//       {/* Logo Section */}
//       <div style={logoStyle}>
//         <img src="https://placehold.co/40x40" alt="Logo" className="me-2"/>
//         <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>WANotifier</span>
//       </div>

//       {/* Profile Menu */}
//       <div 
//         style={profileSectionStyle}
//         onClick={() => navigate('/profile')} // Navigate to the profile page
//       >
//         <i className="fas fa-user-circle fa-2x"></i> {/* Profile Icon */}
//         <span style={profileTextStyle}>User Name</span> {/* User Name */}
//       </div>

//       {/* Navigation Menu */}
//       <nav>
//         <ul className="list-unstyled">
//           {menuItems.map((item, index) => (
//             <li key={index} className="mb-2">
//               <div 
//                 style={menuItemStyle} 
//                 onClick={(e) => {
//                   if (item.children) {
//                     toggleSubMenu(index);  // Toggle submenu if it exists
//                   } else {
//                     navigate(item.path);  // Navigate to the path if no children
//                   }
//                 }}
//                 className="d-flex align-items-center justify-content-between"
//               >
//                 <div>
//                   <i className={`fas ${item.icon} me-2`}></i>
//                   {item.label}
//                 </div>
//                 {item.children && (
//                   <i 
//                     className={`fas fa-chevron-${openMenu === index ? 'up' : 'down'}`}
//                   ></i>
//                 )}
//               </div>
//               {/* Render Submenu if exists */}
//               {item.children && openMenu === index && (
//                 <ul style={submenuStyle} className="list-unstyled">
//                   {item.children.map((child, childIndex) => (
//                     <li key={childIndex}>
//                       <Link 
//                         to={child.path}  // Correct navigation for child links
//                         className="d-block text-white py-1 ps-3"
//                         style={{ textDecoration: 'none' }}
//                       >
//                         - {child.label}
//                       </Link>
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default Sidebar;










import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';  
import 'bootstrap/dist/css/bootstrap.min.css'; 

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState(null); 
  const navigate = useNavigate(); 

  const menuItems = [
    { path: '/', icon: 'fa-tachometer-alt', label: 'Dashboard' },
    { 
      path: '/message-templates', 
      icon: 'fa-envelope', 
      label: 'Message Templates',
      children: [
        { path: '/message-templates', label: 'Templates' },
        { path: '/add-templates', label: 'ADD Templates' }
      ]
    },
    { path: '/singlecontacts', icon: 'fa-address-book', label: 'Contacts',
      children: [
        { path: '/singlecontacts', label: 'Single contact' },
        { path: '/Add-new', label: 'Group contact' },
        // { path: '/list-tags', label: 'List&Tags' },
        // { path: '/import-export', label: 'Import/Export' }
      ]
    },
    { 
      path: '/notifications', 
      icon: 'fa-bell', 
      label: 'Notifications',
      children: [
        { path: '/notifications', label:  'Notifications' },
        { path: '/add-notification', label: 'Add New' },
      ]
    },
    { path: '/analytics', icon: 'fa-chart-line', label: 'Analytics',
      children: [
        { path: '/messaging', label: 'Messaging' },
        { path: '/notificationss', label: 'Notifications' }, 
        { path: '/message-templates-analytics', label: 'Message Templates' },
      ]
    },
    { path: '/automations', icon: 'fa-cogs', label: 'Automations',
      children: [
        { path: '/basic', label: 'Basic' },
        { path: '/auto-replies', label: 'Auto Replies' },
      ]
    },
    { path: '/inbox', icon: 'fa-inbox', label: 'Inbox' ,
      children: [
        { path: '/inbox', label: 'Inbox' },
      ]
    },
  ];

  const toggleSubMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  const sidebarStyle = {
    width: '250px',
    height: '100vh',
    backgroundColor: '#075E54',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px'
  };

  const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  const submenuStyle = {
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  };

  const menuItemHoverStyle = {
    backgroundColor: '#495057'
  };

  // Profile Section Style
  const profileSectionStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '20px',
    cursor: 'pointer',
  
    borderRadius: '5px',
  };

  const profileTextStyle = {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: '10px',
  };

  return (
    <div style={sidebarStyle}>
      {/* Logo Section */}
      <div style={logoStyle}>
        <img src="https://placehold.co/40x40" alt="Logo" className="me-2"/>
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>WANotifier</span>
      </div>

      {/* Profile Menu */}
      <div 
        style={profileSectionStyle}
        onClick={() => navigate('/profile')} // Navigate to the profile page
      >
        <i className="fas fa-user-circle fa-2x"></i> {/* Profile Icon */}
        <span style={profileTextStyle}>User Name</span> {/* User Name */}
      </div>

      {/* Navigation Menu */}
      <nav>
        <ul className="list-unstyled">
          {menuItems.map((item, index) => (
            <li key={index} className="mb-2">
              <div 
                style={menuItemStyle} 
                onClick={(e) => {
                  if (item.children) {
                    toggleSubMenu(index);  // Toggle submenu if it exists
                  } else {
                    navigate(item.path);  // Navigate to the path if no children
                  }
                }}
                className="d-flex align-items-center justify-content-between"
              >
                <div>
                  <i className={`fas ${item.icon} me-2`}></i>
                  {item.label}
                </div>
                {item.children && (
                  <i 
                    className={`fas fa-chevron-${openMenu === index ? 'up' : 'down'}`}
                  ></i>
                )}
              </div>
              {/* Render Submenu if exists */}
              {item.children && openMenu === index && (
                <ul style={submenuStyle} className="list-unstyled">
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <Link 
                        to={child.path}  // Correct navigation for child links
                        className="d-block text-white py-1 ps-3"
                        style={{ textDecoration: 'none' }}
                      >
                        - {child.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
