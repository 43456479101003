import React, { useState } from 'react';

const notificationsData = [
  { title: 'new testing code', status: 'ON-GOING', total: 3, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 3, created: '27 Dec, 2023', modified: '27 Dec, 2023' },
  { title: 'Testing0.8', status: 'COMPLETED', total: 1, sent: 1, delivered: 1, read: 1, skipped: 0, failed: 0, created: '18 Dec, 2023', modified: '18 Dec, 2023' },
  { title: 'Testing0.7', status: 'COMPLETED', total: 2, sent: 1, delivered: 1, read: 1, skipped: 1, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'r76687', status: 'COMPLETED', total: 1, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'Testing0.6', status: 'ON-GOING', total: 9, sent: 5, delivered: 5, read: 5, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'new testing code', status: 'ON-GOING', total: 3, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 3, created: '27 Dec, 2023', modified: '27 Dec, 2023' },
  { title: 'Testing0.8', status: 'COMPLETED', total: 1, sent: 1, delivered: 1, read: 1, skipped: 0, failed: 0, created: '18 Dec, 2023', modified: '18 Dec, 2023' },
  { title: 'Testing0.7', status: 'COMPLETED', total: 2, sent: 1, delivered: 1, read: 1, skipped: 1, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'r76687', status: 'COMPLETED', total: 1, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },{ title: 'new testing code', status: 'ON-GOING', total: 3, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 3, created: '27 Dec, 2023', modified: '27 Dec, 2023' },
  { title: 'Testing0.8', status: 'COMPLETED', total: 1, sent: 1, delivered: 1, read: 1, skipped: 0, failed: 0, created: '18 Dec, 2023', modified: '18 Dec, 2023' },
  { title: 'Testing0.7', status: 'COMPLETED', total: 2, sent: 1, delivered: 1, read: 1, skipped: 1, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'r76687', status: 'COMPLETED', total: 1, sent: 0, delivered: 0, read: 0, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'Testing0.6', status: 'ON-GOING', total: 9, sent: 5, delivered: 5, read: 5, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
  { title: 'Testing0.6', status: 'ON-GOING', total: 9, sent: 5, delivered: 5, read: 5, skipped: 0, failed: 0, created: '15 Dec, 2023', modified: '15 Dec, 2023' },
];

const NotificationTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = notificationsData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
      {/* Header */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h3>Notifications</h3>
        <button style={{ backgroundColor: '#28a745', color: '#fff', padding: '8px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
          Add New
        </button>
      </div>

      {/* Table */}
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#f9fafc', borderBottom: '2px solid #e0e0e0' }}>
              <th style={styles.headerCell}>Title</th>
              <th style={styles.headerCell}>Status</th>
              <th style={styles.headerCell}>Total</th>
              <th style={styles.headerCell}>Sent</th>
              <th style={styles.headerCell}>Delivered</th>
              <th style={styles.headerCell}>Read</th>
              <th style={styles.headerCell}>Skipped</th>
              <th style={styles.headerCell}>Failed</th>
              <th style={styles.headerCell}>Created</th>
              <th style={styles.headerCell}>Last Modified</th>
              <th style={styles.headerCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((notification, index) => (
              <tr key={index} style={styles.row}>
                <td style={styles.cell}>{notification.title}</td>
                <td style={styles.cell}>
                  <span
                    style={{
                      backgroundColor: notification.status === 'ON-GOING' ? '#17a2b8' : '#28a745',
                      color: '#fff',
                      padding: '2px 8px',
                      borderRadius: '12px',
                      fontSize: '12px',
                    }}
                  >
                    {notification.status}
                  </span>
                </td>
                <td style={styles.cell}>{notification.total}</td>
                <td style={styles.cell}>{notification.sent}</td>
                <td style={styles.cell}>{notification.delivered}</td>
                <td style={styles.cell}>{notification.read}</td>
                <td style={styles.cell}>{notification.skipped}</td>
                <td style={styles.cell}>{notification.failed}</td>
                <td style={styles.cell}>{notification.created}</td>
                <td style={styles.cell}>{notification.modified}</td>
                <td style={styles.cell}>
            
                  <button style={styles.iconButton}>✏️</button>
                  <button style={styles.iconButton}>🗑️</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div style={styles.pagination}>
        <p>
          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, notificationsData.length)} of {notificationsData.length} entries
        </p>
        <div>
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} style={styles.pageButton}>
            Previous
          </button>
          {[...Array(Math.ceil(notificationsData.length / itemsPerPage))].map((_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              style={{
                ...styles.pageButton,
                fontWeight: currentPage === i + 1 ? 'bold' : 'normal',
              }}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(notificationsData.length / itemsPerPage)}
            style={styles.pageButton}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles
const styles = {
  headerCell: {
    textAlign: 'center',
    padding: '12px',
    borderBottom: '2px solid #e0e0e0',
    backgroundColor: '#f1f3f5',
  },
  row: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '5px',
  },
  cell: {
    textAlign: 'center',
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
  },
  iconButton: {
    marginRight: '5px',
    color: '#555',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
  },
  pageButton: {
    margin: '0 5px',
    padding: '5px 10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
};

export default NotificationTable;
