

import React, { useState } from 'react';
import './CardComponent.css';

const cardData = [
  {
    id: 1,
    title: '👋 Greeting message',
    description: 'Send a greeting message when someone messages you for the very first time...',
    toggle: true,
    fields: { messageText: 'Welcome to our service!' },
  },
  {
    id: 2,
    title: '⏳ Delay in response',
    description: 'Send an automatic message when someone messages you...',
    toggle: true,
    fields: { delayMinutes: 10, messageText: 'We’ll get back to you soon' },
  },
  {
    id: 3,
    title: '🛑 Unsubscribe',
    description: 'Automatically unsubscribe a contact from receiving future messages...',
    toggle: false,
    fields: { triggerWords: 'Stop', messageText: 'Sorry, we won’t bother you again.' },
  },
  {
    id: 4,
    title: '🔔 Re-subscribe',
    description: 'Automatically change contact subscription status to Subscribed...',
    toggle: true,
    fields: { messageText: 'Welcome back!' },
  },
];

const CardComponent = () => {
  const [cards, setCards] = useState(cardData);
  const [activeForm, setActiveForm] = useState(null);
  const [formData, setFormData] = useState({});

  const openForm = (id) => {
    const card = cards.find((card) => card.id === id);
    setActiveForm(id);
    setFormData({ ...card.fields });
  };

  const closeForm = () => {
    setActiveForm(null);
    setFormData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    setCards((prev) =>
      prev.map((card) =>
        card.id === activeForm ? { ...card, fields: { ...formData } } : card
      )
    );
    closeForm();
  };

  return (
    <div className="cardd-container">
      {cards.map((card) => (
        <div className="cardd" key={card.id}>
          <h3>{card.title}</h3>
          <p>{card.description}</p>
          <button onClick={() => openForm(card.id)}>Edit</button>
        </div>
      ))}

      {/* Pop-up Form */}
      {activeForm !== null && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>{cards.find((card) => card.id === activeForm).title}</h3>
            <form>
              {Object.entries(formData).map(([key, value]) => (
                <div key={key}>
                  <label>{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
                  {typeof value === 'number' ? (
                    <input
                      type="number"
                      name={key}
                      value={value}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <textarea
                      name={key}
                      value={value}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              ))}
            </form>
            <div className="popup-actions">
              <button type="button" className='btn btn-primary' onClick={handleSave}>
                Save
              </button>
              <button type="button" className='btn btn-danger' onClick={closeForm}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardComponent;
