// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Login from './components/Login/Login';
// import Register from './components/Register/Register';
// import ResetPassword from './components/ResetPassword/ResetPassword'; // Add this line

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/reset-password" element={<ResetPassword />} /> {/* Add this route */}
//       </Routes>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import MessageTemplates from './components/MessageTemplates/MessageTemplates';
import Contacts from './components/Contacts/Contacts';
import Notifications from './components/Notifications/Notifications';

import Automations from './components/Automations/Basic';
import Inbox from './components/Inbox/Inbox';
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageTemplateForm from './components/MessageTemplates/MessageTemplateForm';
import ContactTable from './components/Contacts/Contacts';
import AddContactForm from './components/Contacts/AddContactForm';
import AddContact from './components/Contacts/AddContactForm';
import ImportExportContacts from './components/Contacts/ImportExportContacts';
import ContactListsAndTags from './components/Contacts/ContactListsAndTags';
import NotificationTable from './components/Notifications/Notifications';
import AddNotification from './components/Notifications/AddNotification';
import Messaging from './components/Analytics/Messaging';
import NotificationAnalytics from './components/Analytics/NotificationAnalytics';
import MessageTemplatesAnalytics from './components/Analytics/MessageTemplatesAnalytics';
import Basic from './components/Automations/Basic';
import AutoReplies from './components/Automations/AutoReplies';
import ChatApp from './components/Inbox/Inbox';
import AccountDetails from './components/Profile/AccountDetails';

const App = () => {
    return (
        <Router>
            <div className="d-flex flex-column flex-md-row vh-100">
                {/* Sidebar */}
                <Sidebar />
                
                {/* Main Content Area */}
                <div className="flex-grow-1 d-flex flex-column">
                    {/* Header */}
                    <Header />
                    
                    {/* Page Content */}
                    <div className="p-3 overflow-auto" style={{ backgroundColor: '#f8f9fa' }}>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/message-templates" element={<MessageTemplates />} />
                            <Route path="/singlecontacts" element={<ContactTable />} />
                            <Route path="/notifications" element={<NotificationTable />} />
                            <Route path="/messaging" element={<Messaging/>} />
                            <Route path="/automations" element={<Automations />} />
                            <Route path="/inbox" element={<ChatApp/>} />
                            <Route path='/add-templates' element={<MessageTemplateForm/>}/>
                            <Route path='/add-new' element={<AddContact/>}/>
                            <Route path='/import-export' element={<ImportExportContacts/>}/>
                            <Route path='/list-tags' element={<ContactListsAndTags/>}/>
                            <Route path='/add-notification' element={<AddNotification/>}/>
                            <Route path='/notificationss' element={<NotificationAnalytics/>}/>
                            <Route path='/message-templates-analytics' element={<MessageTemplatesAnalytics/>}/>
                            <Route path='/basic' element={<Basic/>}/>
                            <Route path='/auto-replies' element={<AutoReplies/>}/>
                            <Route path='/profile' element={<AccountDetails/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default App;
