import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Dropdown } from 'react-bootstrap';
import './add.css'

// const AddNotification = () => {
//   const [selectedNotificationType, setSelectedNotificationType] = useState('Marketing');
//   const [showContactDropdown, setShowContactDropdown] = useState(false);
//   const [selectedContactList, setSelectedContactList] = useState('');
//   const [showExtraFields, setShowExtraFields] = useState(false);
//   const [sendWhen, setSendWhen] = useState('immediately');
//   const [selectedDateTime, setSelectedDateTime] = useState('');
//   const [selectedTemplate, setSelectedTemplate] = useState('');
//   const [particularNumber, setParticularNumber] = useState('');

//   const contactLists = [
//     { name: 'Others', contacts: 4 },
//     { name: 'XYZ', contacts: 1 },
//     { name: 'Website Leads', contacts: 1 },
//     { name: 'Event Attendees', contacts: 2 },
//     { name: 'Default', contacts: 3 },
//     { name: 'Set1', contacts: 250 },
//   ];

//   const templates = [
//     { name: 'wanotifier_team (en)' },
//     { name: 'test_character_limit_templat...' },
//     { name: 'text_template_011_new_011...' },
//     { name: 'lto_02_copy_new' },
//     { name: 'update_lto02_copy' }
//   ];

//   const handleNotificationTypeChange = (type) => {
//     setSelectedNotificationType(type);
//     setShowExtraFields(true);
//     setSelectedContactList('');
//     setParticularNumber('');
//   };

//   const handleSelectContactList = (list) => {
//     setSelectedContactList(`${list.name} (${list.contacts} contacts)`);
//     setShowContactDropdown(false);
//     setShowExtraFields(true);
//   };

//   return (
//     <Container fluid className="p-4">
//       <Row>
//         {/* 9 Columns - Main Form */}
//         <Col md={8}>
//           <h4 className="mb-4">Add Notification</h4>
//           <Form>
//             {/* Title */}
//             <Form.Group className="mb-3">
//               <Form.Label>Title</Form.Label>
//               <Form.Control type="text" placeholder="Enter Title" />
//             </Form.Group>

//             {/* Notification Type */}
//             <Form.Label>Notification Type</Form.Label>
//             <Row className="mb-3">
//               <Col>
//                 <div
//                   className={`p-3 border rounded ${
//                     selectedNotificationType === 'Marketing' ? 'border-primary' : ''
//                   }`}
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => handleNotificationTypeChange('Marketing')}
//                 >
//                   <strong>Marketing</strong>
//                   <p style={{ fontSize: '12px' }}>Send bulk messages to multiple contacts</p>
//                 </div>
//               </Col>
//               <Col>
//                 <div
//                   className={`p-3 border rounded ${
//                     selectedNotificationType === 'Transactional' ? 'border-primary' : ''
//                   }`}
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => handleNotificationTypeChange('Transactional')}
//                 >
//                   <strong>Transactional</strong>
//                   <p style={{ fontSize: '12px' }}>Send triggered notifications</p>
//                 </div>
//               </Col>
//             </Row>

//             {/* Conditional Rendering */}
//             {selectedNotificationType === 'Marketing' ? (
//               <Form.Group className="mb-3">
//                 <Form.Label>Contact Lists</Form.Label>
//                 <Dropdown>
//                   <Dropdown.Toggle variant="secondary" id="dropdown-basic">
//                     {selectedContactList || 'Select Contact List'}
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     {contactLists.map((list, index) => (
//                       <Dropdown.Item key={index} onClick={() => handleSelectContactList(list)}>
//                         {list.name} ({list.contacts} contacts)
//                       </Dropdown.Item>
//                     ))}
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </Form.Group>
//             ) : (
//               <Form.Group className="mb-3">
//                 <Form.Label>Particular Number</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter Particular Number"
//                   value={particularNumber}
//                   onChange={(e) => setParticularNumber(e.target.value)}
//                 />
//               </Form.Group>
//             )}

            // {/* Send When */}
            // <Form.Group className="mb-3">
            //   <Form.Label>Send This When</Form.Label>
            //   <div>
            //     <Form.Check
            //       type="radio"
            //       label="Immediately"
            //       name="sendWhen"
            //       checked={sendWhen === 'immediately'}
            //       onChange={() => setSendWhen('immediately')}
            //     />
            //     <Form.Check
            //       type="radio"
            //       label="Later"
            //       name="sendWhen"
            //       checked={sendWhen === 'later'}
            //       onChange={() => setSendWhen('later')}
            //     />
            //   </div>
            //   {sendWhen === 'later' && (
            //     <Form.Control
            //       type="datetime-local"
            //       className="mt-2"
            //       onChange={(e) => setSelectedDateTime(e.target.value)}
            //     />
            //   )}
            // </Form.Group>

            
//             <Form.Group className="mb-3">
//               <Form.Label>Message Template</Form.Label>
//               <Form.Select onChange={(e) => setSelectedTemplate(e.target.value)} value={selectedTemplate}>
//                 <option value="">Select Template</option>
//                 {templates.map((template, index) => (
//                   <option key={index} value={template.name}>
//                     {template.name}
//                   </option>
//                 ))}
//               </Form.Select>
//             </Form.Group>

//             <Button variant="primary" type="submit">
//               Submit
//             </Button>
//           </Form>
//         </Col>

//         {/* 3 Columns - Extra Design */}
//         <Col md={4}>
//           <div className="p-4 border rounded bg-light">
//             <h5>Quick Tips</h5>
//             <ul>
//               <li>Ensure the title is descriptive.</li>
//               <li>Transactional notifications require a valid number.</li>
//               <li>Use templates for faster setup.</li>
//               <li>Schedule your notifications if needed.</li>
//             </ul>
//           </div>

//           <div className="p-4 border rounded mt-4 bg-white">
//             <h6 className="mb-3">Notification Preview</h6>
//             <p><strong>Type:</strong> {selectedNotificationType}</p>
//             <p><strong>Send When:</strong> {sendWhen === 'immediately' ? 'Immediately' : selectedDateTime}</p>
//             <p><strong>Template:</strong> {selectedTemplate || 'Not selected'}</p>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default AddNotification;



const AddNotification = () => {
  const [formData, setFormData] = useState({
    title: '',
    selectedNotificationType: 'Marketing',
    selectedContactList: '',
    particularNumber: '',
    sendWhen: 'immediately',
    selectedDateTime: '',
    selectedTemplate: 'wanotifier_team (en)',
    headerMediaVisible: false,
    bodyVariablesVisible: false,
    buttonVariablesVisible: false,
    footerVariablesVisible: false,
  });

  const contactLists = [
    { name: 'Others', contacts: 4 },
    { name: 'XYZ', contacts: 1 },
    { name: 'Website Leads', contacts: 1 },
    { name: 'Event Attendees', contacts: 2 },
    { name: 'Default', contacts: 3 },
    { name: 'Set1', contacts: 250 },
  ];

  // Templates array with data for each template
  const templates = [
    {
      name: 'wanotifier_team (en)',
      header: {
        type: 'image',
        content: 'https://placehold.co/300x200', // Image URL
      },
      body: {
        text: 'Hello, <br />Please visit our store and use the coupon.',
      },
      footer: 'footer text here',
      buttons: [
        { type: 'external-link', text: 'Visit Website', icon: 'fas fa-external-link-alt' },
        { type: 'copy', text: 'Copy Offer Code', icon: 'fas fa-copy' },
        { type: 'phone', text: 'Call Phone', icon: 'fas fa-phone-alt' },
      ],
    },
    {
      name: 'test_character_limit_templat...',
      header: null,
      body: {
        text: 'Dear, <br />We have an exciting offer for you.',
      },
      footer: 'footer text here',
      buttons: [
        { type: 'external-link', text: 'Visit Website', icon: 'fas fa-external-link-alt' },
      ],
    },
    {
      name: 'text_template_011_new_011...',
      header: {
        type: 'image',
        content: 'https://placehold.co/300x200', // Image URL
      },
      body: {
        text: 'Hi, <br />Check out our latest products.',
      },
      footer: 'footer text here',
      buttons: [
        { type: 'external-link', text: 'Visit Website', icon: 'fas fa-external-link-alt' },
        { type: 'copy', text: 'Copy Offer Code', icon: 'fas fa-copy' },
      ],
    },
  ];

  // Find the selected template object
  const selectedTemplateData = templates.find((template) => template.name === formData.selectedTemplate);

  const renderPreview = () => {
    if (!selectedTemplateData) return null; // If no template data is found

    return (
      <div className="card p-3">
        <h5>Template Preview</h5>
        <div className="template-preview">
          {/* Dynamic Header */}
          {selectedTemplateData.header && selectedTemplateData.header.type === 'image' && (
            <div className="template-body">
              <img
                src={selectedTemplateData.header.content}
                alt="Header Preview"
                className="template-header-image"
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </div>
          )}

          {/* Body */}
          <div style={{ backgroundColor: 'white' }}>
            <div className="template-body">
              <p dangerouslySetInnerHTML={{ __html: selectedTemplateData.body.text }} />
              <div className="d-flex flex-row align-items-center justify-content-between">
                <small>{selectedTemplateData.footer}</small>
                <small className="template-footer">10:00 AM</small>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="template-buttons">
            {selectedTemplateData.buttons.map((button, index) => (
              <button key={index}>
                <i className={button.icon}></i> {button.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleNotificationTypeChange = (type) => {
    setFormData({
      ...formData,
      selectedNotificationType: type,
      selectedContactList: '',
      particularNumber: '',
    });
  };

  const handleSelectContactList = (list) => {
    setFormData({
      ...formData,
      selectedContactList: `${list.name} (${list.contacts} contacts)`,
    });
  };

  const handleTemplateChange = (event) => {
    const template = event.target.value;
    setFormData({
      ...formData,
      selectedTemplate: template,
      headerMediaVisible: false,
      bodyVariablesVisible: false,
      buttonVariablesVisible: false,
      footerVariablesVisible: false,
    });

    if (template === 'wanotifier_team (en)') {
      setFormData({
        ...formData,
        headerMediaVisible: true,
        bodyVariablesVisible: true,
        buttonVariablesVisible: true,
      });
    } else if (template === 'test_character_limit_templat...') {
      setFormData({
        ...formData,
        bodyVariablesVisible: true,
        footerVariablesVisible: true,
      });
    } else if (template === 'text_template_011_new_011...') {
      setFormData({
        ...formData,
        headerMediaVisible: true,
        bodyVariablesVisible: true,
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Log formData or send it to the server
    console.log(formData);
  };

  return (
    <Container fluid className="p-4">
      <Row>
        {/* Left Panel - Form */}
        <Col md={7}>
          <h4 className="mb-4">Add Notification</h4>
          <Form onSubmit={handleFormSubmit}>
            {/* Title */}
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              />
            </Form.Group>

            {/* Notification Type */}
            <Form.Label>Notification Type</Form.Label>
            <Row className="mb-3">
              <Col>
                <div
                  className={`p-3 border rounded ${formData.selectedNotificationType === 'Marketing' ? 'border-primary' : ''}`}
                  onClick={() => handleNotificationTypeChange('Marketing')}
                  style={{ cursor: 'pointer' }}
                >
                  <strong>Marketing</strong>
                  <p style={{ fontSize: '12px' }}>Send bulk messages to multiple contacts</p>
                </div>
              </Col>
              <Col>
                <div
                  className={`p-3 border rounded ${formData.selectedNotificationType === 'Transactional' ? 'border-primary' : ''}`}
                  onClick={() => handleNotificationTypeChange('Transactional')}
                  style={{ cursor: 'pointer' }}
                >
                  <strong>Transactional</strong>
                  <p style={{ fontSize: '12px' }}>Send triggered notifications</p>
                </div>
              </Col>
            </Row>

            {/* Conditional Rendering */}
            {formData.selectedNotificationType === 'Marketing' ? (
              <Form.Group className="mb-3">
                <Form.Label>Contact Lists</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary">{formData.selectedContactList || 'Select Contact List'}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {contactLists.map((list, index) => (
                      <Dropdown.Item key={index} onClick={() => handleSelectContactList(list)}>
                        {list.name} ({list.contacts} contacts)
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>Particular Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Particular Number"
                  value={formData.particularNumber}
                  onChange={(e) => setFormData({ ...formData, particularNumber: e.target.value })}
                />
              </Form.Group>
            )}

            {/* Send When */}
            <Form.Group className="mb-3">
              <Form.Label>Send This When</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Immediately"
                  name="sendWhen"
                  checked={formData.sendWhen === 'immediately'}
                  onChange={() => setFormData({ ...formData, sendWhen: 'immediately' })}
                />
                <Form.Check
                  type="radio"
                  label="Later"
                  name="sendWhen"
                  checked={formData.sendWhen === 'later'}
                  onChange={() => setFormData({ ...formData, sendWhen: 'later' })}
                />
              </div>
              {formData.sendWhen === 'later' && (
                <Form.Control
                  type="datetime-local"
                  className="mt-2"
                  value={formData.selectedDateTime}
                  onChange={(e) => setFormData({ ...formData, selectedDateTime: e.target.value })}
                />
              )}
            </Form.Group>

            {/* Message Template */}
            <Form.Group className="mb-3">
              <Form.Label>Message Template</Form.Label>
              <Form.Select value={formData.selectedTemplate} onChange={handleTemplateChange}>
                {templates.map((template, index) => (
                  <option key={index} value={template.name}>
                    {template.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* Send Button */}
            <Button variant="primary" type="submit">Send</Button>
          </Form>
        </Col>

        {/* Right Panel - Preview */}
        <Col md={5}>
          <div className="p-4 border rounded bg-light">
            <h6 className="mb-3">Notification Preview</h6>
            {renderPreview()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNotification;

  

