import React, { useState, useEffect } from 'react';
import { FaPlus, FaAddressBook, FaPhoneAlt, FaList, FaEdit, FaTrashAlt } from 'react-icons/fa';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';

const Contacts = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [contacts, setContacts] = useState([]);
  const [editingContact, setEditingContact] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const contactsPerPage = 10; // Contacts per page

  const handleNameChange = (e) => setName(e.target.value);
  const handleMobileChange = (value) => setMobile(value);

  // Fetch contacts from the server on component mount
  useEffect(() => {
    axios.get('https://chatbotapi.emedha.in/pages/api/singleContact')
      .then(response => setContacts(response.data))
      .catch(error => console.error('Error fetching contacts:', error));
  }, []);

  // Add a new contact
  const handleAddContact = () => {
    if (name && mobile) {
      const newContact = { name, mobile };
      axios.post('https://chatbotapi.emedha.in/pages/api/singleContact/add', newContact)
        .then(response => {
          setContacts([...contacts, response.data]);
          setName('');
          setMobile('');
        })
        .catch(error => console.error('Error adding contact:', error));
    } else {
      alert('Please enter both name and mobile.');
    }
  };

  // Edit an existing contact
  const handleEditContact = (contact) => {
    setEditingContact(contact);
    setName(contact.name);
    setMobile(contact.mobile);
  };

  // Update the contact in the database
  const handleUpdateContact = () => {
    if (editingContact && name && mobile) {
      const updatedContact = { ...editingContact, name, mobile };
      axios.put(`https://chatbotapi.emedha.in/pages/api/singleContact/update/${editingContact.id}`, updatedContact)
        .then(response => {
          const updatedContacts = contacts.map(contact =>
            contact.id === editingContact.id ? response.data : contact
          );
          setContacts(updatedContacts);
          setEditingContact(null);
          setName('');
          setMobile('');
        })
        .catch(error => console.error('Error updating contact:', error));
    } else {
      alert('Please enter both name and mobile.');
    }
  };

  // Delete a contact
  const handleDeleteContact = (contactId) => {
    axios.delete(`https://chatbotapi.emedha.in/pages/api/singleContact/delete/${contactId}`)
      .then(response => {
        const updatedContacts = contacts.filter(contact => contact.id !== contactId);
        setContacts(updatedContacts);
      })
      .catch(error => console.error('Error deleting contact:', error));
  };

  // Pagination logic
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = contacts.slice(indexOfFirstContact, indexOfLastContact);

  const totalPages = Math.ceil(contacts.length / contactsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ marginBottom: '20px' }}>
        <h2><FaAddressBook /> {editingContact ? 'Edit' : 'Add'} Contact</h2>
        <table className="table table-bordered" style={{ width: '100%', marginTop: '10px', marginBottom: '20px' }}>
          <thead>
            <tr>
              <th><FaAddressBook /> Name</th>
              <th><FaPhoneAlt /> Mobile</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="text" value={name} onChange={handleNameChange} placeholder="Enter name" style={{ width: '100%', padding: '8px', borderRadius: '4px' }} /></td>
              <td><PhoneInput international value={mobile} onChange={handleMobileChange} placeholder="Enter mobile" style={{ width: '100%', padding: '8px', borderRadius: '4px' }} /></td>
            </tr>
          </tbody>
        </table>

        <button onClick={editingContact ? handleUpdateContact : handleAddContact} className="btn btn-success" style={{ display: 'block', marginTop: '10px', fontSize: '16px', cursor: 'pointer' }}>
          <FaPlus style={{ marginRight: '10px' }} /> {editingContact ? 'Update' : 'Add'} Contact
        </button>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3><FaList /> Contact List</h3>
        <table className="table table-striped table-bordered" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th><FaAddressBook /> Name</th>
              <th><FaPhoneAlt /> Mobile</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentContacts.map((contact, index) => (
              <tr key={index}>
                <td>{contact.name}</td>
                <td>{contact.mobile}</td>
                <td>
                  <button onClick={() => handleEditContact(contact)} className="btn btn-warning btn-sm me-2"><FaEdit /></button>
                  <button onClick={() => handleDeleteContact(contact.id)} className="btn btn-danger btn-sm"><FaTrashAlt /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination controls */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
            className="btn btn-secondary btn-sm me-2"
          >
            Previous
          </button>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <button 
              key={pageNumber} 
              onClick={() => handlePageChange(pageNumber + 1)} 
              className={`btn btn-secondary btn-sm me-2 ${currentPage === pageNumber + 1 ? 'active' : ''}`}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
            className="btn btn-secondary btn-sm"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
