import React from 'react';
import { Dropdown } from 'react-bootstrap';  // Import React-Bootstrap Dropdown
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();  // Hook for navigation

    const handleLogout = () => {
        // Add your logout logic here, e.g., clearing the authentication token
        console.log('Logged out');
        navigate('/login');  // Redirect to the login page after logout
    };

    // Define the inline styles for the dropdown items
    const dropdownItemStyle = {
        textDecoration: 'none', 
         // Remove underline on hover
        color: 'black',  // Default text color
    };

    const dropdownItemHoverStyle = {
        backgroundColor: '#075E54', 
        textDecoration: 'none', // Change background color on hover
        color: 'white',  // Change text color to white
    };

    return (
        <header className="bg-light d-flex justify-content-between align-items-center px-3 py-2 shadow-sm">
            <h5 className="m-0">WANotifier</h5>
            <div>
                <Dropdown align="end" autoClose="outside" drop="down">
                    <Dropdown.Toggle variant="outline-secondary" size="sm" id="profile-dropdown">
                        Profile
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item 
                            href="/profile" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-user me-2"></i> My Account
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="#" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-phone me-2"></i> WhatsApp Numbers
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="#" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-list me-2"></i> My Subscriptions
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="#" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-plug me-2"></i> Integrations
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="#" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-gift me-2"></i> Refer and Earn
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="#" 
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-bug me-2"></i> Report Bug
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item 
                            onClick={handleLogout}
                            style={dropdownItemStyle} 
                            onMouseEnter={(e) => e.target.style = { ...dropdownItemStyle, ...dropdownItemHoverStyle }}
                            onMouseLeave={(e) => e.target.style = dropdownItemStyle}
                        >
                            <i className="fas fa-sign-out-alt me-2"></i> Log Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    );
};

export default Header;
