import React from 'react';
import './SubscriptionPopup.css';

const SubscriptionPopup = ({ onClose }) => {
  return (
    <div className="subscription-popup-overlay">
      <div className="subscription-popup-content">
        {/* Close Button */}
        <button className="close-btn" onClick={onClose}>✖️</button>
        
        {/* Header */}
        <h2 className="popup-title">Select Your Subscription Plan</h2>
        <p className="popup-note">
          <strong>Note:</strong> Total Cost = WANotifier Subscription Charges + WhatsApp Cloud API 
          <a href="#"> Per-Conversation Charges</a>
        </p>

        {/* Toggle Buttons */}
        <div className="toggle-buttons">
          <button className="toggle-btn active">Monthly</button>
          <button className="toggle-btn">Yearly (Save 20%)</button>
        </div>

        {/* Subscription Plans */}
        <div className="plans-container">
          {/* Standard Plan */}
          <div className="plan-card">
            <h3>Standard</h3>
            <p className="plan-price">₹1,999<span>/month</span></p>
            <p className="plan-desc">+ 18% GST • Billed monthly</p>
            <ul>
              <li>Up to 5,000 contacts</li>
              <li>Up to 15 tags & attributes</li>
              <li>Up to 5 transactional notifications</li>
              <li>Setup up to 5 auto-replies</li>
            </ul>
            <button className="plan-btn">Upgrade Now</button>
          </div>

          {/* Growth Plan */}
          <div className="plan-card popular">
            <h3>Growth <span className="popular-badge">POPULAR</span></h3>
            <p className="plan-price">₹3,999<span>/month</span></p>
            <p className="plan-desc">+ 18% GST • Billed monthly</p>
            <ul>
              <li>Up to 25,000 contacts</li>
              <li>Up to 50 tags & attributes</li>
              <li>Up to 25 transactional notifications</li>
              <li>Setup up to 25 auto-replies</li>
            </ul>
            <button className="plan-btn">Upgrade Now</button>
          </div>

          {/* Pro Plan */}
          <div className="plan-card">
            <h3>Pro</h3>
            <p className="plan-price">₹9,999<span>/month</span></p>
            <p className="plan-desc">+ 18% GST • Billed monthly</p>
            <ul>
              <li>Up to 2,50,000 contacts</li>
              <li>Unlimited tags & attributes</li>
              <li>Unlimited transactional notifications</li>
              <li>Setup unlimited auto-replies</li>
            </ul>
            <button className="plan-btn">Upgrade Now</button>
          </div>
        </div>

        {/* Footer */}
        <div className="popup-footer">
          <p>Want to send messages to more than 2,50,000 contacts?</p>
          <button className="contact-btn">Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPopup;
