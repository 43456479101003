// // MessageTemplatesAnalytics.jsx
// import React, { useState } from 'react';
// import { Bar, Line } from 'react-chartjs-2';
// import 'chart.js/auto';

// const MessageTemplatesAnalytics = () => {
//   const lineData = {
//     labels: ['31 Mar 2024', '02 Apr 2024', '04 Apr 2024', '06 Apr 2024', '08 Apr 2024', '10 Apr 2024', '12 Apr 2024'],
//     datasets: [
//       {
//         label: 'Sent',
//         data: [1, 2, 3, 4, 5, 6, 7],
//         borderColor: 'green',
//         backgroundColor: 'green',
//         fill: false,
//       },
//       {
//         label: 'Delivered',
//         data: [1, 2, 3, 4, 5, 6, 7],
//         borderColor: 'blue',
//         backgroundColor: 'blue',
//         fill: false,
//       },
//       {
//         label: 'Read',
//         data: [1, 2, 3, 4, 5, 6, 7],
//         borderColor: 'cyan',
//         backgroundColor: 'cyan',
//         fill: false,
//       },
//       {
//         label: 'Clicks',
//         data: [1, 2, 3, 4, 5, 6, 7],
//         borderColor: 'black',
//         backgroundColor: 'black',
//         fill: false,
//       },
//     ],
//   };

//   const barData = {
//     labels: ['HELP', 'INTRESTED', 'NOT INTRESTED'],
//     datasets: [
//       {
//         label: 'HELP',
//         data: [3, 0, 0],
//         backgroundColor: 'green',
//       },
//       {
//         label: 'INTRESTED',
//         data: [0, 6, 0],
//         backgroundColor: 'blue',
//       },
//       {
//         label: 'NOT INTRESTED',
//         data: [0, 0, 4],
//         backgroundColor: 'cyan',
//       },
//     ],
//   };

//   // Inline style objects
//   const containerStyle = {
//     width: '90%',
//     margin: '20px auto',
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//   };

//   const headerStyle = {
//     fontSize: '24px',
//     fontWeight: '500',
//     marginBottom: '20px',
//   };

//   const formGroupStyle = {
//     marginBottom: '20px',
//   };

//   const labelStyle = {
//     display: 'block',
//     fontWeight: '500',
//     marginBottom: '5px',
//   };

//   const inputSelectStyle = {
//     width: '100%',
//     padding: '10px',
//     border: '1px solid #ccc',
//     borderRadius: '4px',
//   };

//   const chartsStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//     flexWrap: 'wrap',
//   };

//   const chartContainerStyle = {
//     width: '48%',
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//     marginBottom: '20px',
//   };

//   const chartHeaderStyle = {
//     fontSize: '18px',
//     fontWeight: '500',
//     marginBottom: '10px',
//   };

//   const chartStatsStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: '20px',
//   };

//   const chartStatsItemStyle = {
//     fontSize: '16px',
//   };

//   const chartStatsItemBoldStyle = {
//     fontWeight: '700',
//   };




//   const handleTemplateChange = (event) => {
//     setSelectedTemplate(event.target.value);
//   };

//   const templates = [
//     { name: 'wanotifier_team (en)', preview: 'Say Hello! to WANotifier 👋', status: 'APPROVED', created: '10 Apr, 2024', modified: '10 Apr, 2024' },
//     { name: 'test_character_limit_templat...', preview: 'Contrary to popular belief, L...', status: 'APPROVED', created: '29 Mar, 2024', modified: '29 Mar, 2024' },
//     { name: 'text_template_011_new_011...', preview: 'Hello {{1}}, Thank you for sh...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
//     { name: 'lto_02_copy_new', preview: 'Honest feedbacks from som...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
//     { name: 'update_lto02_copy', preview: 'Check here!!!', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' }
//   ];

//   const [selectedTemplate, setSelectedTemplate] = useState(templates[0].name);

//   return (
//     <div style={containerStyle}>
//       <div style={headerStyle}>Message Template Analytics</div>
//       <div style={formGroupStyle}>
//         <label htmlFor="template" style={labelStyle}>Select a Template</label>
//         <select id="template" style={inputSelectStyle} value={selectedTemplate} onChange={handleTemplateChange}>
//           {templates.map((template, index) => (
//             <option key={index} value={template.name}>{template.name}</option>
//           ))}
//         </select>
//       </div>
//       <div style={formGroupStyle}>
//         <label htmlFor="date-range" style={labelStyle}>Select Date Range</label>
//         <input id="date-range" type="text" value="01/04/2024 12:00 AM - 12/04/2024 05:20 PM" readOnly style={inputSelectStyle} />
//       </div>
//       <div style={chartsStyle}>
//         <div style={chartContainerStyle}>
//           <div style={chartHeaderStyle}>Quick Overview</div>
//           <div style={chartStatsStyle}>
//             <div style={chartStatsItemStyle}>Sent <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
//             <div style={chartStatsItemStyle}>Delivered <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
//             <div style={chartStatsItemStyle}>Read <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
//             <div style={chartStatsItemStyle}>Button Clicks <span style={chartStatsItemBoldStyle}>13 (186%)</span></div>
//           </div>
//           <Line data={lineData} />
//         </div>
//         <div style={chartContainerStyle}>
//           <div style={chartHeaderStyle}>Button Clicks</div>
//           <div style={chartStatsStyle}>
//             <div style={chartStatsItemStyle}>Button Text</div>
//             <div style={chartStatsItemStyle}>Type</div>
//             <div style={chartStatsItemStyle}>Clicks</div>
//           </div>
//           <div style={chartStatsStyle}>
//             <div style={chartStatsItemStyle}>HELP</div>
//             <div style={chartStatsItemStyle}>Quick Reply</div>
//             <div style={chartStatsItemStyle}>3 (43%)</div>
//           </div>
//           <div style={chartStatsStyle}>
//             <div style={chartStatsItemStyle}>INTRESTED</div>
//             <div style={chartStatsItemStyle}>Quick Reply</div>
//             <div style={chartStatsItemStyle}>6 (86%)</div>
//           </div>
//           <div style={chartStatsStyle}>
//             <div style={chartStatsItemStyle}>NOT INTRESTED</div>
//             <div style={chartStatsItemStyle}>Quick Reply</div>
//             <div style={chartStatsItemStyle}>4 (57%)</div>
//           </div>
//           <Bar data={barData} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MessageTemplatesAnalytics;

import React, { useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; 
import { Card, Table, Row, Col, Container } from 'react-bootstrap'; // Import CSS for DatePicker

const MessageTemplatesAnalytics = () => {

  const [startDate, setStartDate] = useState(new Date('2024-04-01T00:00:00'));
  const [endDate, setEndDate] = useState(new Date('2024-04-12T17:20:00'));

  const templates = [
    { name: 'wanotifier_team (en)', preview: 'Say Hello! to WANotifier 👋', status: 'APPROVED', created: '10 Apr, 2024', modified: '10 Apr, 2024' },
    { name: 'test_character_limit_templat...', preview: 'Contrary to popular belief, L...', status: 'APPROVED', created: '29 Mar, 2024', modified: '29 Mar, 2024' },
    { name: 'text_template_011_new_011...', preview: 'Hello {{1}}, Thank you for sh...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
    { name: 'lto_02_copy_new', preview: 'Honest feedbacks from som...', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' },
    { name: 'update_lto02_copy', preview: 'Check here!!!', status: 'APPROVED', created: '12 Feb, 2024', modified: '12 Feb, 2024' }
  ];

  const [selectedTemplate, setSelectedTemplate] = useState(templates[0].name);


    const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false); // For toggling the start date picker
    const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false); // For toggling the end date picker
  
    // Toggle between date pickers based on user's action
    const handleStartDateChange = (date) => {
      setStartDate(date);
      setIsStartDatePickerOpen(false); // Close start date picker after selection
      setIsEndDatePickerOpen(true); // Open end date picker
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
      setIsEndDatePickerOpen(false); // Close end date picker after selection
    };
  
  

  const lineData = {
    labels: ['31 Mar 2024', '02 Apr 2024', '04 Apr 2024', '06 Apr 2024', '08 Apr 2024', '10 Apr 2024', '12 Apr 2024'],
    datasets: [
      {
        label: 'Sent',
        data: [1, 2, 3, 4, 5, 6, 7],
        borderColor: 'green',
        backgroundColor: 'green',
        fill: false,
      },
      {
        label: 'Delivered',
        data: [1, 2, 3, 4, 5, 6, 7],
        borderColor: 'blue',
        backgroundColor: 'blue',
        fill: false,
      },
      {
        label: 'Read',
        data: [1, 2, 3, 4, 5, 6, 7],
        borderColor: 'cyan',
        backgroundColor: 'cyan',
        fill: false,
      },
      {
        label: 'Clicks',
        data: [1, 2, 3, 4, 5, 6, 7],
        borderColor: 'black',
        backgroundColor: 'black',
        fill: false,
      },
    ],
  };

  const barData = {
    labels: ['HELP', 'INTRESTED', 'NOT INTRESTED'],
    datasets: [
      {
        label: 'HELP',
        data: [3, 0, 0],
        backgroundColor: 'green',
      },
      {
        label: 'INTRESTED',
        data: [0, 6, 0],
        backgroundColor: 'blue',
      },
      {
        label: 'NOT INTRESTED',
        data: [0, 0, 4],
        backgroundColor: 'cyan',
      },
    ],
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const containerStyle = {
    width: '90%',
    margin: '20px auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: '500',
    marginBottom: '20px',
  };

  const formGroupStyle = {
    marginBottom: '20px',
  };

  const labelStyle = {
    display: 'block',
    fontWeight: '500',
    marginBottom: '5px',
  };

  const inputSelectStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  const chartsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  };

  const chartContainerStyle = {
    width: '48%',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const chartHeaderStyle = {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px',
  };

  const chartStatsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  };

  const chartStatsItemStyle = {
    fontSize: '16px',
  };

  const chartStatsItemBoldStyle = {
    fontWeight: '700',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>Message Template Analytics</div>
      <div style={formGroupStyle}>
        <label htmlFor="template" style={labelStyle}>Select a Template</label>
        <select id="template" style={inputSelectStyle} value={selectedTemplate} onChange={handleTemplateChange}>
          {templates.map((template, index) => (
            <option key={index} value={template.name}>{template.name}</option>
          ))}
        </select>
      </div>
      <Container fluid className="mt-4">
     <Row className="mb-4">
        <Col>
      
          <div>SELECT DATE RANGE</div>
          <div>
            {/* Start Date Picker */}
            
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="Start Date"
              open={isStartDatePickerOpen} // Control if the picker is open
              onClickOutside={() => setIsStartDatePickerOpen(false)} // Close picker if clicking outside
              onFocus={() => setIsStartDatePickerOpen(true)} // Open picker on focus
            />
            <span> to </span>
            {/* End Date Picker */}
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              showTimeSelect
              dateFormat="Pp"
              placeholderText="End Date"
              open={isEndDatePickerOpen} // Control if the picker is open
              onClickOutside={() => setIsEndDatePickerOpen(false)} // Close picker if clicking outside
              onFocus={() => setIsEndDatePickerOpen(true)} // Open picker on focus
            />
          </div>
        </Col>
      </Row>
      </Container>
      <div style={chartsStyle}>
        <div style={chartContainerStyle}>
          <div style={chartHeaderStyle}>Quick Overview</div>
          <div style={chartStatsStyle}>
            <div style={chartStatsItemStyle}>Sent <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
            <div style={chartStatsItemStyle}>Delivered <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
            <div style={chartStatsItemStyle}>Read <span style={chartStatsItemBoldStyle}>7 (100%)</span></div>
            <div style={chartStatsItemStyle}>Button Clicks <span style={chartStatsItemBoldStyle}>13 (186%)</span></div>
          </div>
          <Line data={lineData} />
        </div>
        <div style={chartContainerStyle}>
          <div style={chartHeaderStyle}>Button Clicks</div>
          <div style={chartStatsStyle}>
            <div style={chartStatsItemStyle}>Button Text</div>
            <div style={chartStatsItemStyle}>Type</div>
            <div style={chartStatsItemStyle}>Clicks</div>
          </div>
          <div style={chartStatsStyle}>
            <div style={chartStatsItemStyle}>HELP</div>
            <div style={chartStatsItemStyle}>Quick Reply</div>
            <div style={chartStatsItemStyle}>3 (43%)</div>
          </div>
          <div style={chartStatsStyle}>
            <div style={chartStatsItemStyle}>INTRESTED</div>
            <div style={chartStatsItemStyle}>Quick Reply</div>
            <div style={chartStatsItemStyle}>6 (86%)</div>
          </div>
          <div style={chartStatsStyle}>
            <div style={chartStatsItemStyle}>NOT INTRESTED</div>
            <div style={chartStatsItemStyle}>Quick Reply</div>
            <div style={chartStatsItemStyle}>4 (57%)</div>
          </div>
          <Bar data={barData} />
        </div>
      </div>
    </div>
  );
};

export default MessageTemplatesAnalytics;
